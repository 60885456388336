import React, {useEffect, useState} from 'react';
import {Tooltip, makeStyles} from '@material-ui/core';

const useStyles = (width: number) =>
  makeStyles((_theme) => ({
    customWidth: {
      maxWidth: `min(90vw + 16px, ${width + 16}px)`,
    },
  }));

function BuildPhotoAvatar({
  signedUrl,
  thumbnailUrl,
  pxSize = 30,
  tooltipPxSize = 500,
  imgStyle = {},
}: {
  pxSize?: number;
  signedUrl?: string;
  thumbnailUrl?: string;
  tooltipPxSize?: number;
  imgStyle?: React.CSSProperties;
}) {
  const styles = useStyles(tooltipPxSize)();
  const [internalImageUrl, setInternalImageUrl] = useState<string>();
  const [internalThumbnailUrl, setInternalThumbnailUrl] = useState<string>();

  useEffect(() => {
    if (signedUrl) {
      setInternalImageUrl(signedUrl);
    } else {
      setInternalImageUrl(undefined);
    }
  }, [signedUrl, internalImageUrl]);

  useEffect(() => {
    if (thumbnailUrl) {
      setInternalThumbnailUrl(thumbnailUrl);
    } else {
      setInternalThumbnailUrl(undefined);
    }
  }, [thumbnailUrl, internalThumbnailUrl]);

  if (!internalThumbnailUrl && !internalImageUrl) return <></>;

  return (
    <Tooltip
      classes={{tooltip: styles.customWidth}}
      title={<img src={internalImageUrl} alt="Build avatar" style={{width: `min(90vw, ${tooltipPxSize}px)`}} />}
    >
      <img
        src={internalThumbnailUrl ?? internalImageUrl}
        alt="Build avatar"
        style={{height: pxSize, width: pxSize, borderRadius: pxSize, ...imgStyle}}
      />
    </Tooltip>
  );
}

export default BuildPhotoAvatar;

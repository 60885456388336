import React from 'react';
import {IBuild, BuildState} from '@common/api/models/builds/IBuild';
import {DateWithTimeTooltip, EllipsisTextColumnTooltip} from './sharedColumns';
import {Box} from '@material-ui/core';
import BuildPhotoAvatar from '../../../../components/atoms/BuildPhotoAvatar';
import StatusChip from '../../../../components/atoms/Status/StatusChip';
export interface BuildsRow {
  name: string;
  state: BuildState;
  machine: string;
  created: string | Date;
  start?: string | Date;
  end?: string | Date;
  batch: string;
  url: string;
  build: IBuild;
  avatarUrl?: string;
  thumbnailUrl?: string;
}

const buildTableSchema = [
  {
    title: 'Name',
    field: 'name',
    render: (row: BuildsRow) => {
      return (
        <Box display="flex" alignItems="center">
          <EllipsisTextColumnTooltip ellipsisTextProps={{tooltip: row.name}}>{row.name}</EllipsisTextColumnTooltip>
          <BuildPhotoAvatar signedUrl={row.avatarUrl} thumbnailUrl={row.thumbnailUrl} imgStyle={{marginLeft: '8px'}} />
        </Box>
      );
    },
  },
  {
    title: 'State',
    field: 'state',
    width: '148px',
    render: (row: BuildsRow) => {
      return <StatusChip state={row.state} />;
    },
  },
  {title: 'Machine', sortField: 'machineName', field: 'machine'},
  {title: 'Batch', sortField: 'batchName', field: 'batch'},
  {
    title: 'Created',
    field: 'created',
    sortField: 'createdAt',
    type: 'date' as const,
    width: '124px',
    render: (row: BuildsRow) => <DateWithTimeTooltip timestamp={row.created} />,
  },
  {
    title: 'Start',
    field: 'start',
    type: 'date' as const,
    emptyValue: '',
    width: '124px',
    render: (row: BuildsRow) => <DateWithTimeTooltip timestamp={row.start} />,
  },
  {
    title: 'End',
    field: 'end',
    type: 'date' as const,
    emptyValue: '',
    width: '124px',
    render: (row: BuildsRow) => <DateWithTimeTooltip timestamp={row.end} />,
  },
  {
    title: 'Layer',
    field: 'layer',
    sortField: 'numLayers',
    emptyValue: '',
    width: '110px',
    sortable: false,
    align: 'right',
  },
];

export default buildTableSchema;

import React, {useState, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {Card, TextField, Button, Grid} from '@material-ui/core';
import {IBuild} from '@common/api/models/builds/IBuild';

import {useUserStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';
import {createBuildNotePOST} from '../../../api/ajax/buildNotes';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import GenericTable from '../Table/GenericTable';
import {BuildNoteRow} from '../../../pages/shared/IndexPage/tableSchemas/buildNote';

export function NotesCard({build}: {build: IBuild}) {
  const isSmall = useSmallScreenSize();

  const useBuildNotes = () => {
    const buildNotes = useSelector((s: RootState) => s.buildNoteStore.list);
    const users = useSelector((state: RootState) => state.userStore.byId);
    const userStoreActions = useUserStoreActions();

    useEffect(() => {
      userStoreActions.ensureConsistent({});
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return buildNotes.map<BuildNoteRow>((note) => {
      const user = users[note.userUuid];
      const userName = user ? `${user.firstName} ${user.lastName}` : 'Unknown User';

      return {
        ...note,
        userName: userName,
      };
    });
  };

  const [isNotePending, setIsNotePending] = useState(false);
  const [isUpdatingNotes, setIsUpdatingNotes] = useState(false);
  const canSubmit = !isUpdatingNotes && isNotePending;

  const textArea = useRef() as any;
  const saveNotes = async () => {
    const textAreaValue = textArea.current.value;
    if (!textAreaValue) {
      return;
    }

    // Lock the button so that user cannot click it again
    setIsUpdatingNotes(true);
    await createBuildNotePOST(build.uuid, textAreaValue);

    // Unlock the create not button and clear the text area
    setIsUpdatingNotes(false);
    textArea.current.value = '';
    setIsNotePending(false);
  };

  const clearPendingNote = () => {
    textArea.current.value = '';
    setIsNotePending(false);
  };

  return (
    <Card
      style={{
        padding: isSmall ? '15px' : '30px',
        marginBottom: isSmall ? '15px' : '30px',
        height: '100%',
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <GenericTable
            resourceType="buildNote"
            filteringEnabled={false}
            useData={useBuildNotes}
            tableTitle="Build Notes"
            permanentFilters={{buildUuid: build.uuid}}
          />
        </Grid>

        <Grid item xs={12} md={8} xl={6}>
          <TextField
            inputRef={textArea}
            style={{marginBottom: '10px', marginTop: '10px'}}
            id="notes"
            multiline
            minRows={2}
            maxRows={6}
            variant="outlined"
            fullWidth
            placeholder={'Add note here'}
            disabled={isUpdatingNotes}
            onChange={() => setIsNotePending(!!textArea.current.value)}
            onKeyDown={(event: React.KeyboardEvent) =>
              // Submit on control+enter
              event.keyCode === 13 && event.ctrlKey && canSubmit && saveNotes()
            }
          />

          <Grid container spacing={3} justifyContent="flex-end">
            <Grid item>
              <Button disabled={!canSubmit} style={{display: 'block', marginLeft: 'auto'}} onClick={clearPendingNote}>
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!canSubmit}
                style={{display: 'block', marginLeft: 'auto'}}
                variant="contained"
                onClick={saveNotes}
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

import * as React from 'react';
import {useSelector} from 'react-redux';
import {Grid} from '@material-ui/core';
import {IBuild} from '@common/api/models/builds/IBuild';
import {Role} from '@common/api/models/users/IUser';
import {BuildMetaCard} from '../../../../components/molecules/Cards/BuildMetaCard';
import {UploadedFilesCard} from '../../../../components/molecules/Cards/UploadedFilesCard';
import {NotificationRecipientsCard} from '../../../../components/molecules/Cards/NotificationRecipientsCard';
import {NotesCard} from '../../../../components/molecules/Cards/NotesCard';
import UserAccess from './UserAccess';
import {RootState} from '../../../../store/reducers/index';
import {BuildPhotosSection} from '../../../../components/molecules/Cards/BuildPhotosCard';
import {BuildEventsCard} from '../../../../components/molecules/Cards/BuildEventsCard';

export interface GeneralInfoProps {
  build: IBuild;
}

export function GeneralInfo({build}: GeneralInfoProps) {
  const currentUser = useSelector((state: RootState) => state.auth.user!);
  const canViewPermissions = currentUser.role >= Role.MANAGER || build.provisionerUuid === currentUser.uuid;

  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <BuildMetaCard build={build} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <UploadedFilesCard build={build} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <BuildPhotosSection buildUuid={build.uuid} orgUuid={build.organizationUuid} />
        </Grid>

        {!!canViewPermissions && (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <UserAccess build={build} />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <NotificationRecipientsCard build={build} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <BuildEventsCard buildUuid={build.uuid} />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <NotesCard build={build} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import React from 'react';
import {CTReportStatus, ICTReportGETResponse} from '@common/api/models/builds/data/ICTReport';
import {Box, Typography} from '@material-ui/core';
import {CTUploadDataStep} from './CTUploadDataStep';
import {DefectsLoading, FailureStep, PointCloudsLoading, ProcessingUploadedData} from './CTLoadingSteps';
import CTAlignmentStep from './CTAlignmentStep';
import CTResultsStep from './CTResultsStep';
import {grayColor} from '../../../assets/jss/material-dashboard-react';
import {useExtraSmallScreenSize} from '../../../utils/utilHooks';

const stepMap: {[status: string]: CTReportStep} = {
  [CTReportStatus.DRAFT]: {
    component: CTUploadDataStep,
    title: 'Step 1: Upload CT Data',
  },
  [CTReportStatus.SUBMITTED]: {
    component: ProcessingUploadedData,
    title: 'Step 2: Alignment',
  },
  [CTReportStatus.GENERATING]: {
    component: PointCloudsLoading,
    title: 'Step 2: Alignment',
  },
  [CTReportStatus.ALIGNMENT]: {
    component: CTAlignmentStep,
    title: 'Step 2: Alignment',
    subtitle:
      'Use the 3D tools to rotate and align the CT point cloud (red) with our 3D model of your part. When ready, click "Process Aligned CT Data" to continue.',
  },
  [CTReportStatus.ALIGNING]: {
    component: DefectsLoading,
    title: 'Step 3: Results',
  },
  [CTReportStatus.COMPLETED]: {
    component: CTResultsStep,
    title: 'CT Analysis Results',
  },
  [CTReportStatus.FAILED]: {
    component: FailureStep,
    title: 'CT Report Generation Failed',
  },
};

export interface CommonCTStepProps {
  currentCTReport: ICTReportGETResponse;
}

interface CTReportStep {
  title: string;
  subtitle?: string;
  component: React.ComponentType<CommonCTStepProps>;
}

function CTReportStepView({currentReport}: {currentReport: ICTReportGETResponse}) {
  const isXsScreen = useExtraSmallScreenSize();
  const removeMargins = currentReport.status === CTReportStatus.COMPLETED && isXsScreen;
  const currentStep = stepMap[currentReport.status];

  return (
    <Box
      height="calc(100% - 48px)"
      width={removeMargins ? '100%' : 'calc(100% - 48px)'}
      display="flex"
      flexDirection="column"
      margin={removeMargins ? '0px' : '24px'}
    >
      <Typography variant="h5">{currentStep.title}</Typography>
      {currentStep.subtitle && (
        <Typography style={{color: grayColor[7], fontSize: '0.85rem', paddingTop: '6px', fontWeight: 500}}>
          {currentStep.subtitle}
        </Typography>
      )}
      <Box flex={1} width="100%" display="flex" flexDirection="column" height="100%" margin="24px 0px 0px 0px">
        <currentStep.component currentCTReport={currentReport} />
      </Box>
    </Box>
  );
}

export default CTReportStepView;

import React, {useEffect, useState} from 'react';
import styled, {createGlobalStyle, ThemeProps} from 'styled-components';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {spacing} from '@material-ui/system';
import {CssBaseline, Paper as MuiPaper, withWidth} from '@material-ui/core';
import {isWidthUp, isWidthDown} from '@material-ui/core/withWidth';
import {RootState} from '../store/reducers';
import {Route} from '../routes/routes';
import {resendEmailConfirmation} from '../api/ajax/auth';
import * as AuthActions from '../store/actions/auth';
import Sidebar from '../components/organisms/Sidebar';
import {useActions} from '../store/actions';
import ErrorBoundary from '../utils/ErrorBoundary';
import envConfig from '../envConfig';
import {useSmallScreenSize} from '../utils/utilHooks';
import BuildDataArchiveDownloadNotifications from '../utils/BuildDataArchiveDownloadNotifications';

const GlobalStyle = createGlobalStyle<ThemeProps<{body: any}>>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled.div<{pageHasBottomToolbar?: boolean; numBuildDataDownloads?: number}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({pageHasBottomToolbar, numBuildDataDownloads}) =>
    pageHasBottomToolbar && numBuildDataDownloads
      ? `padding-bottom: ${4 + numBuildDataDownloads * 3}rem;`
      : pageHasBottomToolbar
      ? 'padding-bottom: 4rem;'
      : numBuildDataDownloads
      ? `padding-bottom: ${numBuildDataDownloads * 3}rem;`
      : ''}
  // I've no clue why setting the div's width works but it seems to be required
  // to make tables scroll horizontally properly. 100px is arbitrary, anything
  // seems to work.
  width: 100px;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)<{sidebarOpen: boolean; isSmallScreen: boolean}>`
  flex: 1;
  background: ${(props) => props.theme.body.background};
  margin-left: ${(props) => (props.isSmallScreen ? '0px' : props.sidebarOpen ? '260px' : '64px')};
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const setSidebarStorage = (sidebarState: 'expanded' | 'collapsed') => {
  localStorage.setItem(`sidebarState`, JSON.stringify({sidebarState}));
};

const getSidebarStorage = () => {
  const state = JSON.parse(localStorage.getItem(`sidebarState`) || '{}');

  if (state.sidebarState) {
    return state.sidebarState;
  }
  return null;
};

const getSidebarState = () => {
  const sidebarState = getSidebarStorage();

  if (sidebarState === 'collapsed') {
    return false;
  } else if (sidebarState === 'expanded') {
    return true;
  } else {
    return false;
  }
};

const Dashboard = ({
  children,
  routes,
  width,
  currentRoute,
}: {
  children: any;
  routes: Route[];
  width: any;
  currentRoute: Route;
}) => {
  const isSmallScreen = useSmallScreenSize();
  const [sidebarOpen, setSidebarOpen] = useState(getSidebarState());
  const authActions = useActions(AuthActions);
  const auth = useSelector((state: RootState) => state.auth);
  const params = useParams();
  const pageHasBottomToolbar = !!(
    currentRoute.pageHasBottomToolbar && currentRoute.pageHasBottomToolbar(params, width)
  );
  const numBuildDataDownloads = useSelector((state: RootState) => state.buildDataArchive.numDownloads);

  const handleResend = async () => {
    const res = await resendEmailConfirmation();
    if (res.success) {
      toast('Confirmation email resent. Please check your inbox.', {
        type: 'success',
      });
    }
  };

  useEffect(() => {
    if (!auth.emailConfirmationShown && !envConfig.isOnPremise) {
      if (auth.user) {
        if (!auth.user.emailConfirmed && auth.user.email) {
          toast(
            <React.Fragment>
              <b>Please confirm your email address</b>
              <p>
                <i>Click here to resend confirmation email</i>
              </p>
            </React.Fragment>,
            {type: 'info', onClick: handleResend}
          );
          authActions.emailConfirmationToastShown();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.emailConfirmationShown, auth.user]);

  const handleDrawerToggle = () => {
    const newState = sidebarOpen ? 'collapsed' : 'expanded';
    setSidebarStorage(newState);
    setSidebarOpen(!sidebarOpen);
  };

  const desktopRoutes = routes
    .filter((r) => !r.hideOnDesktopBar)
    .map((r) => {
      if ('children' in r) {
        return {
          ...r,
          children: r.children.filter((rc) => !rc.hideOnDesktopBar),
        };
      } else {
        return r;
      }
    });
  const mobileRoutes = routes
    .filter((r) => !r.hideOnMobileBar)
    .map((r) => {
      if ('children' in r) {
        return {
          ...r,
          children: r.children.filter((rc) => !rc.hideOnMobileBar),
        };
      } else {
        return r;
      }
    });

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />

      <Sidebar
        PaperProps={{style: {width: 260}}}
        routes={isSmallScreen ? mobileRoutes : desktopRoutes}
        variant="persistent"
        sidebarOpen={sidebarOpen}
        toggleSidebar={handleDrawerToggle}
      />
      <AppContent pageHasBottomToolbar={pageHasBottomToolbar} numBuildDataDownloads={numBuildDataDownloads}>
        <MainContent
          p={isWidthUp('lg', width) ? 10 : isWidthDown('xs', width) ? 5 : 7}
          sidebarOpen={sidebarOpen}
          isSmallScreen={isSmallScreen}
        >
          <BuildDataArchiveDownloadNotifications
            sidebarOpen={sidebarOpen}
            pageHasBottomToolbar={pageHasBottomToolbar}
          />
          <ErrorBoundary>{children}</ErrorBoundary>
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default withWidth()(Dashboard);

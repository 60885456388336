import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {camelize, humanize, inflect, titleize, underscore} from 'inflection';
import {pickBy} from 'lodash';
import {Box, Button, GridSize} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {SortDirection, FiltersState, UseDataFn} from '../../../pages/shared/IndexPage/IndexPage';
import {QueryFilter} from '../../../store/actions/liveUpdateStore';
import IndexTable from '../../../pages/shared/IndexPage/IndexTable';
import {ResourceTypes} from '../../../pages/shared/IndexPage/IndexPage';
import actions from '../../../store/actions';
import {MaterialTableProps} from 'material-table';
import {FilterHeader} from '../../../pages/shared/IndexPage/FilterHeader';
import {RootState} from '../../../store/reducers';

export interface GenericTableProps {
  resourceType: ResourceTypes;
  useData: UseDataFn;
  filteringEnabled: boolean;
  onRowClick?: (
    event: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData: {[key: string]: any; onClickUrl: string} | undefined
  ) => void;
  tableTitle?: string | JSX.Element;
  tableHeader?: string | JSX.Element;
  tableHeaderFilters?: Array<{field: string; smGridSize: GridSize}>;
  permanentFilters?: QueryFilter<any>;
  defaultFilters?: QueryFilter<any>;
  tableOptions?: MaterialTableProps<any>['options'];
  tableActions?: MaterialTableProps<any>['actions'];
  minTableWidth?: string;
  forcedRefresh?: number;
  headerFlexDirection?: 'row' | 'row-reverse';
  tableProps?: Partial<MaterialTableProps<any>>;
  showFilterModal?: boolean;
  ignoreColumns?: string[];
  onSelectionChange?: (selectedRows: any) => void;
  customFilters?: string[];
  onCustomFiltersCommitted?: (filters: QueryFilter<any>) => void;
  shouldCountWsInserts?: boolean;
}

const GenericTable = ({
  resourceType,
  useData,
  filteringEnabled,
  onRowClick,
  tableTitle,
  tableHeader,
  tableHeaderFilters,
  tableOptions,
  tableActions,
  permanentFilters,
  defaultFilters,
  minTableWidth,
  forcedRefresh,
  headerFlexDirection = 'row',
  tableProps,
  showFilterModal,
  ignoreColumns,
  onSelectionChange,
  customFilters,
  onCustomFiltersCommitted,
  shouldCountWsInserts = false,
}: GenericTableProps) => {
  const camelizedResourceType = camelize(underscore(resourceType));

  const [internalSelectedRows, setInternalSelectedRows] = useState<number>(0);

  const handleSelectionChange = (selectedRows: any) => {
    setInternalSelectedRows(selectedRows.length);
    if (onSelectionChange) {
      onSelectionChange(selectedRows);
    }
  };

  const [filters, setFilters] = useState<FiltersState>({
    take: 25,
    page: 0,
    ...(defaultFilters || {}),
  });

  const resourceActions = actions[`use${camelizedResourceType}StoreActions`]();
  const listUpdatesCount = useSelector((state: RootState) => {
    const store = state[`${resourceType}Store` as keyof RootState] as any;
    return store.listUpdatesCount;
  });

  useEffect(() => {
    return () => {
      resourceActions.unsubscribeFromList();
      resourceActions.invalidateListHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, forcedRefresh]);

  useEffect(() => {
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permanentFilters, filters, forcedRefresh]);

  const fetchList = (ignorePrevious: boolean = false) => {
    const {page, ...params} = filters;
    const skip = page * params.take;
    const wsQuery = permanentFilters || {};
    const query = {...wsQuery, ...params, skip};

    const customQuery = pickBy(query, (value, key) => customFilters?.includes(key));
    const finalQuery = pickBy(query, (value, key) => !customFilters?.includes(key));

    if (onCustomFiltersCommitted) {
      onCustomFiltersCommitted(customQuery);
    }

    resourceActions.fetchAndSubscribeToList(finalQuery, wsQuery, {
      unpaginated: tableOptions?.paging === false,
      shouldCountWsInserts,
      ignorePrevious,
    });
  };

  const handlePageChange = (newPageNumber: number) => {
    setFilters({...filters, page: newPageNumber});
  };
  const handleRowsPerPageChange = (rowsPerPage: number) => {
    setFilters({
      ...filters,
      take: rowsPerPage,
      page: 0,
    });
  };
  const handleSortOrderChange = (field: string, direction: SortDirection | null) => {
    const {sortBy: _sortBy, ...newFilters} = filters;
    if (!direction) setFilters(newFilters);
    else setFilters({...newFilters, sortBy: {[field]: direction}});
  };

  return (
    <TableContainer className="generic-table-container">
      <FilterHeader
        filteringEnabled={filteringEnabled}
        filters={filters}
        resourceType={resourceType}
        setFilters={setFilters}
        headerFlexDirection={headerFlexDirection}
        tableHeader={tableHeader}
        tableTitle={tableTitle}
        tableHeaderFilters={tableHeaderFilters}
        showFilterModal={showFilterModal}
      />

      {shouldCountWsInserts && listUpdatesCount > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          width="100%"
          marginBottom="-24px"
          marginTop="6px"
        >
          <Button size="small" variant="text" onClick={() => fetchList(true)} color="primary">
            <span
              style={{
                width: '8px',
                height: '8px',
                display: 'inline-block',
                backgroundColor: '#1976d2',
                borderRadius: '8px',
                marginRight: '6px',
              }}
            />
            <b>
              {listUpdatesCount} new {inflect(titleize(humanize(underscore(resourceType))), listUpdatesCount)}
            </b>
          </Button>
        </Box>
      )}

      <IndexTable
        filters={filters}
        resourceType={resourceType}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        handleSortOrderChange={handleSortOrderChange}
        useData={useData}
        onRowClick={onRowClick}
        tableOptions={{
          ...tableOptions,
          ...(onSelectionChange ? {toolbar: internalSelectedRows > 0, selection: true} : {}),
        }}
        tableActions={tableActions}
        minTableWidth={minTableWidth}
        tableProps={{...tableProps, ...(onSelectionChange ? {onSelectionChange: handleSelectionChange} : {})}}
        ignoreColumns={ignoreColumns}
      />
    </TableContainer>
  );
};

export default GenericTable;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

import {ResourceMessage} from '@common/api/websocket/resourceMessage';
import {WSQueryFilter} from '../../store/actions/liveUpdateStore';
import * as qs from 'qs';

const NORMAL_CLOSURE_CODE = 1000;
const UNAUTHORIZED = 'Unauthorized'; // expected response message from server

/**
 * Subscribe to live resource messages over websockets
 * @param url API's url directory; e.g. /api/v1/users/
 * @param onMsg Callback function for newly received messages
 * @param onClose Callback function for onClose events
 * @param onError Callback function for onError events
 * @param wsFilter Optional query filter for websocket connection
 */
export async function subscribeToLiveResource<T>(
  url: string,
  onMsg: (msg: ResourceMessage<T>) => any,
  onClose: () => any,
  onError: (msg: string) => any,
  wsFilter?: WSQueryFilter<T>
): Promise<WebSocket> {
  return new Promise<WebSocket>((res, rej) => {
    // Get websocket URI
    const HOST = window.location.origin.replace(/^http(s?)/, 'ws$1');
    let fullUrl = HOST + url;
    if (wsFilter) {
      fullUrl += '?' + qs.stringify(wsFilter, {arrayFormat: 'brackets'});
    }

    const ws = new WebSocket(fullUrl);
    let handled = false; // TODO: explain use

    // Callback methods:
    ws.onmessage = (wsMsg: MessageEvent) => {
      if (wsMsg.type === 'message') {
        if (wsMsg.data === UNAUTHORIZED) return; // ignore unauthorized
        const data: ResourceMessage<T> = JSON.parse(wsMsg.data as string);
        onMsg(data);
      } else {
        throw new Error(`unknown websocket message type ${JSON.stringify(wsMsg.type)} ${JSON.stringify(wsMsg)}`);
      }
    };

    ws.onclose = (ev: CloseEvent) => {
      if (ev.code !== NORMAL_CLOSURE_CODE) {
        onError(ev.code + ' ' + ev.reason);
      }
      onClose();
    };

    ws.onerror = (ev: Event) => {
      if (!handled) {
        handled = true;
        rej(ev);
      }
    };

    ws.onopen = () => {
      if (!handled) {
        handled = true;
        res(ws);
      }
    };
  });
}

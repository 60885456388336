import React, {useEffect, useState} from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';

import {toast} from 'react-toastify';
import {Alert} from '@material-ui/lab';

import {useHistory, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {RootState} from '../../store/reducers';
import {useUserStoreActions} from '../../store/actions';
import {getRoleName, IUser, Role} from '@common/api/models/users/IUser';
import {canCreateWithRole, canDeleteUser, canEditUser} from '@common/api/models/users/User.access';
import {validateEmail, validateFirstName, validateLastName} from '@common/api/models/users/User.validation';
import {validator} from '@common/api/types';
import {ResourcePermissionType} from '@common/api/models/users/IResourcePermission';
import ViewEditFieldRow from '../../components/atoms/ViewEditFieldRow';
import {DialogButton} from '../../components/molecules/DialogButton';
import {userDELETE, userPATCH, userResetPasswordPOST} from '../../api/ajax/users';
import {changeOwnPasswordPOST, resendEmailConfirmation} from '../../api/ajax/auth';
import envConfig from '../../envConfig';
import ResourcePermissionsTable from '../../components/molecules/Table/ResourcePermissionsTable';
import Header from '../../components/organisms/Header';
import ConditionalTooltip from '../../components/atoms/Texts/ConditionalTooltip';
import LoadingPage from '../../components/organisms/LoadingPage';

function ChangeOwnPassword() {
  const [changing, setChanging] = useState(false);
  const [currentPW, setCurrentPW] = useState('');
  const [newPW1, setNewPW1] = useState('');
  const [newPW2, setNewPW2] = useState('');

  const changePasswordClick = () => {
    setChanging(true);
  };

  const cancelClick = () => {
    setChanging(false);
  };

  const confirmClick = async () => {
    if (newPW1 === newPW2) {
      const res = await changeOwnPasswordPOST(currentPW, newPW1);
      if (res.success) {
        setChanging(false);
      }
    } else {
      toast("Passwords don't match", {type: 'warning'});
    }
  };

  return (
    <Card>
      <CardContent
        style={{
          paddingBottom: '16px',
        }}
      >
        <Grid container justifyContent="space-between" spacing={6}>
          <Grid item xs={12}>
            {changing ? (
              <React.Fragment>
                <Button variant={'outlined'} onClick={cancelClick}>
                  Cancel
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={confirmClick}
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  Confirm
                </Button>
              </React.Fragment>
            ) : (
              <Button variant={'outlined'} onClick={changePasswordClick}>
                Change password
              </Button>
            )}
          </Grid>
          {changing && (
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>Current Password</b>
                    </TableCell>
                    <TableCell>
                      <TextField
                        type={'password'}
                        defaultValue={currentPW}
                        onChange={(e) => setCurrentPW(e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>New Password</b>
                    </TableCell>
                    <TableCell>
                      <TextField type={'password'} defaultValue={newPW1} onChange={(e) => setNewPW1(e.target.value)} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Confirm New Password</b>
                    </TableCell>
                    <TableCell>
                      <TextField type={'password'} defaultValue={newPW2} onChange={(e) => setNewPW2(e.target.value)} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

function UserActions(props: {user: IUser}) {
  const auth = useSelector((state: RootState) => state.auth);
  const history = useHistory();
  const [changing, setChanging] = useState(false);
  const [newPW1, setNewPW1] = useState('');
  const [newPW2, setNewPW2] = useState('');

  const resetPasswordClick = () => {
    setChanging(true);
  };

  const cancelClick = () => {
    setChanging(false);
  };

  const confirmResetPassword = async () => {
    if (newPW1 === newPW2) {
      const res = await userResetPasswordPOST(props.user.uuid, newPW1);
      if (res.success) {
        setChanging(false);
      }
    } else {
      toast("Passwords don't match", {type: 'warning'});
    }
  };

  const sendResetPasswordEmail = async () => {
    const res = await userResetPasswordPOST(props.user.uuid, newPW1);
    if (res.success) {
      toast('Reset password email sent.', {type: 'info'});
    }
  };

  const handleDeleteUser = async () => {
    const res = await userDELETE(props.user.uuid);
    if (res.success) {
      history.goBack();
    }
  };

  return (
    <Card>
      <CardContent
        style={{
          paddingBottom: '16px',
        }}
      >
        <Grid container justifyContent="space-between" spacing={6}>
          <Grid item xs={6}>
            {changing ? (
              <React.Fragment>
                <Button variant={'outlined'} onClick={cancelClick}>
                  Cancel
                </Button>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={confirmResetPassword}
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  Confirm
                </Button>
              </React.Fragment>
            ) : (
              <Button
                variant={'outlined'}
                onClick={envConfig.isOnPremise ? resetPasswordClick : sendResetPasswordEmail}
              >
                {envConfig.isOnPremise ? 'Reset Password' : 'Send Reset Password Email'}
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            {auth.user && canDeleteUser(props.user, auth.user) && (
              <DialogButton
                dialog={{
                  title: `Remove User`,
                  content: `Are you sure you wish to remove the user - ${props.user.firstName} ${props.user.lastName}?`,
                }}
                handleConfirm={handleDeleteUser}
                text={'Delete User'}
                style={{
                  float: 'right',
                }}
              />
            )}
          </Grid>
          {changing && (
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>New Password</b>
                    </TableCell>
                    <TableCell>
                      <TextField type={'password'} defaultValue={newPW1} onChange={(e) => setNewPW1(e.target.value)} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <b>Confirm New Password</b>
                    </TableCell>
                    <TableCell>
                      <TextField type={'password'} defaultValue={newPW2} onChange={(e) => setNewPW2(e.target.value)} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default function UserPage() {
  const userStoreActions = useUserStoreActions();
  const auth = useSelector((state: RootState) => state.auth);
  const {uuid} = useParams<{uuid: string}>();
  const user = useSelector((state: RootState) => state.userStore.byId[uuid]);

  useEffect(() => {
    if (uuid) {
      userStoreActions.ensureConsistent({
        uuid: [uuid, auth.user!.uuid],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, auth.user]);

  const availableRoles = canCreateWithRole(auth.user!);
  const history = useHistory();

  const {hash} = history.location;
  if (hash !== '') {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }

  const handleGoBack = () => {
    history.goBack();
  };

  if (!user) {
    return <LoadingPage loadingText="Loading User" />;
  }

  const canEdit = !!auth.user && canEditUser(user, auth.user);

  const handleResend = async () => {
    const res = await resendEmailConfirmation();
    if (res.success) {
      toast('Confirmation email resent. Please check your inbox.', {
        type: 'success',
      });
    }
  };

  const isCurrentUser = user.uuid === auth.user?.uuid;
  const canViewPermissions = (auth.user?.role || 0) >= Role.MANAGER || isCurrentUser;

  return (
    <React.Fragment>
      <Header
        helmet={`${user.lastName ? user.firstName + ' ' + user.lastName : user.firstName} User Profile`}
        title={`${user.lastName ? user.firstName + ' ' + user.lastName : user.firstName}'s profile`}
        breadcrumbs={[{title: 'Users', path: '/users'}, user.firstName + ' ' + user.lastName]}
      />

      <Grid container spacing={6} justifyContent={'center'}>
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardHeader title={'Account'}></CardHeader>
            <CardContent>
              <Grid container alignContent={'center'} spacing={2}>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <AccountCircle style={{fontSize: 100}} />
                </Grid>
                <Grid item xs={12}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{borderBottomStyle: 'none'}}>
                          <b>Name</b>
                        </TableCell>
                        <TableCell style={{borderBottomStyle: 'none', float: 'right'}}>
                          {`${user.firstName} ${user.lastName}`}
                        </TableCell>
                      </TableRow>
                      <ViewEditFieldRow
                        readOnly={!canEdit || availableRoles.length === 0}
                        type={'choice'}
                        name={'Role'}
                        value={user.role}
                        values={availableRoles.map((r) => ({
                          value: r,
                          text: getRoleName(r),
                        }))}
                        valueText={getRoleName(user.role)}
                        saveValue={(v) => userPATCH(user.uuid, {role: v})}
                      />
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={10} lg={8}>
          <Card style={{overflow: 'visible'}}>
            <CardHeader title={'Details'}></CardHeader>
            <CardContent>
              <Table>
                <TableBody>
                  <ViewEditFieldRow
                    readOnly={!canEdit}
                    type={'text'}
                    name={'First Name'}
                    value={user.firstName}
                    validateFn={(value) =>
                      validator(validateFirstName, value, (message) => toast(message, {type: 'error'}))
                    }
                    saveValue={(v) => userPATCH(user.uuid, {firstName: v})}
                  />

                  <ViewEditFieldRow
                    readOnly={!canEdit}
                    type={'text'}
                    name={'Last Name'}
                    value={user.lastName}
                    validateFn={(value) =>
                      validator(validateLastName, value, (message) => toast(message, {type: 'error'}))
                    }
                    saveValue={(v) => userPATCH(user.uuid, {lastName: v})}
                  />

                  <ViewEditFieldRow
                    readOnly={!canEdit}
                    type={'email'}
                    name={envConfig.isOnPremise ? 'Email / Username' : 'Email'}
                    value={user.email || ''}
                    validateFn={(value) => {
                      const emailValidator = (email: string) => validateEmail(email, envConfig.isOnPremise);

                      return validator(emailValidator, value, (message) => toast(message, {type: 'error'}));
                    }}
                    saveValue={async (v) => userPATCH(user.uuid, {email: v})}
                  />

                  {!envConfig.isOnPremise &&
                    user.uuid === auth.user?.uuid &&
                    (user.emailConfirmed ? (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Alert severity={'success'}>Email confirmed!</Alert>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Alert severity={'warning'}>
                            Awaiting confirmation.{' '}
                            <Link onClick={handleResend} style={{cursor: 'pointer'}}>
                              <i>Click here to resend confirmation email</i>
                            </Link>
                          </Alert>
                        </TableCell>
                      </TableRow>
                    ))}

                  <ViewEditFieldRow
                    readOnly={!canEdit}
                    type={'mobile'}
                    name={'Mobile'}
                    value={user.mobile || ''}
                    saveValue={(v) => userPATCH(user.uuid, {mobile: v})}
                  />
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={10} lg={8}>
          <Card style={{overflow: 'visible'}} id="preferences">
            <CardHeader title={'Notification Preferences'}></CardHeader>
            <CardContent style={{paddingTop: '0px', paddingBottom: '0px'}}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{borderBottom: 'none'}}>
                      <strong>Email</strong>
                    </TableCell>
                    <TableCell style={{borderBottom: 'none', float: 'right'}}>
                      <ConditionalTooltip
                        tooltip="Your email is invalid, please set your email above."
                        hideTooltip={validateEmail(user.email).success || !envConfig.isOnPremise}
                      >
                        <Switch
                          checked={user.sendEmail}
                          inputProps={{'aria-label': 'Switch email'}}
                          disabled={envConfig.isOnPremise && !validateEmail(user.email).success}
                          onChange={(event) => {
                            userPATCH(user.uuid, {
                              sendEmail: event.target.checked,
                            });
                          }}
                        />
                      </ConditionalTooltip>
                    </TableCell>
                  </TableRow>
                  {/* SMS Disabled for the time being, it needs a rework. When it comes back, it may be enabled in on-prem */}
                  {/* {!envConfig.isOnPremise && (
                    <TableRow>
                      <TableCell style={{borderBottom: 'none'}}>
                        <strong>Text Message</strong>
                      </TableCell>
                      <TableCell style={{borderBottom: 'none', float: 'right'}}>
                        <Switch
                          inputProps={{'aria-label': 'Switch message'}}
                          checked={user.sendMessage}
                          onChange={(event) => {
                            userPATCH(user.uuid, {
                              sendMessage: event.target.checked,
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
        {isCurrentUser && (
          <Grid item xs={12} md={10} lg={8}>
            <ChangeOwnPassword />
          </Grid>
        )}
        {/* Deleting and Resetting password has the same authorization */}
        {!isCurrentUser && canDeleteUser(user, auth.user!) && (
          <Grid item xs={12} md={10} lg={8}>
            <UserActions user={user} />
          </Grid>
        )}
        {/* No point showing permissions for Admins and above. Technicians can only view their own permissions */}
        {canViewPermissions && user.role < Role.MANAGER && (
          <Grid item xs={12} md={10} lg={8}>
            <Card>
              <CardContent
                style={{
                  paddingBottom: '16px',
                }}
              >
                <Grid container justifyContent={'space-between'}>
                  <Grid item xs={12}>
                    <ResourcePermissionsTable resourceType={ResourcePermissionType.BUILD} userUuid={user.uuid} />
                    <ResourcePermissionsTable resourceType={ResourcePermissionType.MACHINE} userUuid={user.uuid} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} md={10} lg={8}>
          <Card>
            <CardContent
              style={{
                paddingBottom: '16px',
              }}
            >
              <Grid container justifyContent={'space-between'}>
                <Grid item>
                  <Button variant={'outlined'} onClick={handleGoBack}>
                    Go Back
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

import {Box, Button, Card, Chip, CircularProgress, Tooltip, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {useCtReportStoreActions} from '../../store/actions';
import {FetchingState} from '../../store/model/liveUpdateStore';
import {RootState} from '../../store/reducers';
import React from 'react';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import CTReportStepView from './steps/CTSteps';
import Header from '../../components/organisms/Header';
import {useExtraSmallScreenSize} from '../../utils/utilHooks';
import {CTReportStatus} from '@common/api/models/builds/data/ICTReport';

function CTReportPage() {
  const history = useHistory();
  const isXsScreen = useExtraSmallScreenSize();
  const {uuid: ctReportUuid} = useParams<{uuid: string}>();
  const currentCTReport = useSelector((state: RootState) => state.ctReportStore.byId[ctReportUuid]);
  const fetchingReports = useSelector((state: RootState) => state.ctReportStore.fetched === FetchingState.Fetching);

  const ctReportActions = useCtReportStoreActions();

  useEffect(() => {
    if (ctReportUuid) ctReportActions.ensureConsistent({uuid: ctReportUuid, take: 1}, {}, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctReportUuid]);

  useEffect(() => {
    if (currentCTReport && currentCTReport.status === CTReportStatus.COMPLETED) {
      history.replace(`/reports/ct/${ctReportUuid}/results`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCTReport]);

  if (fetchingReports) {
    return (
      <Box height={'100%'} width={'100%'} display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (!currentCTReport) {
    return (
      <>
        <Alert severity="error">CT Report not found</Alert>
        <Button
          variant={'contained'}
          color={'primary'}
          style={{marginTop: 10}}
          onClick={() => history.push('/reports/ct')}
        >
          Return to CT Reports
        </Button>
      </>
    );
  }

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Header
        helmet={currentCTReport.name}
        title={
          <Box display="flex" flexDirection={isXsScreen ? 'column' : 'row'}>
            <Typography
              variant="h4"
              style={{display: 'inline-flex', alignItems: 'center', marginRight: isXsScreen ? '0' : '10px'}}
            >
              {currentCTReport.name}
            </Typography>
            <CTReportBuildInfo />
          </Box>
        }
        breadcrumbs={[
          {title: 'CT Reports', path: '/reports/ct/'},
          {title: currentCTReport.name, path: `/reports/ct/${currentCTReport.uuid}`},
        ]}
      />
      {currentCTReport.status === CTReportStatus.COMPLETED && isXsScreen ? (
        <CTReportStepView currentReport={currentCTReport} />
      ) : (
        <Card style={{flex: 10, height: isXsScreen ? '484px' : undefined}}>
          <CTReportStepView currentReport={currentCTReport} />
        </Card>
      )}
    </Box>
  );
}

export default CTReportPage;

export function CTReportBuildInfo() {
  const isXsScreen = useExtraSmallScreenSize();
  const {uuid: ctReportUuid} = useParams<{uuid: string}>();
  const currentCTReport = useSelector((state: RootState) => state.ctReportStore.byId[ctReportUuid]);

  return (
    <>
      <Tooltip title={<Typography>{currentCTReport.buildName}</Typography>}>
        <Chip
          label={
            <>
              <b>Build:</b> {currentCTReport.buildName}
            </>
          }
          color="primary"
          size="small"
          style={{maxWidth: '100%', marginTop: isXsScreen ? '7px' : '0', marginRight: isXsScreen ? '0px' : '6px'}}
        />
      </Tooltip>
      <Tooltip title={<Typography>{currentCTReport.partName}</Typography>}>
        <Chip
          label={
            <>
              <b>Part:</b> {currentCTReport.partName}
            </>
          }
          color="primary"
          size="small"
          style={{maxWidth: '100%', marginTop: isXsScreen ? '7px' : '0'}}
        />
      </Tooltip>
    </>
  );
}

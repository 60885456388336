import React, {Dispatch, SetStateAction, useCallback, useMemo, useState} from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Delete} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import {RootState} from '../../../store/reducers';
import SliceAttachmentMultipartUpload from './SliceAttachmentMultipartUpload';
import GenericTable from '../../../components/molecules/Table/GenericTable';
import {GenericDialog} from '../../../components/molecules/DialogButton';
import {ISliceAttachmentGETResponse} from '@common/api/models/attachments/ISliceAttachment';
import {sliceAttachmentByUuidDELETE} from '../../../api/ajax/sliceAttachments';
import {isRunningBuildState} from '@common/api/models/builds/IBuild';
import {IBuildAttachmentsGETResponse} from '@common/api/models/attachments/IBuildAttachment';
import {buildAttachmentByUuidDELETE} from '../../../api/ajax/buildAttachments';
import {BuildAttachmentType} from '@common/api/models/attachments/IAttachmentBase';

function SliceFilesUploadModal({
  isOpen,
  setOpen,
  buildUuid,
}: {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  buildUuid: string;
}) {
  const [numFilesUploading, setNumFilesUploading] = useState(0);
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <GenericDialog
      title="Uploaded Slice Files"
      isOpen={isOpen}
      closeDialog={onClose}
      requestInProgress={numFilesUploading > 0}
      closeText="Close"
      content={
        <Box width="100%" style={{overflowY: 'auto'}}>
          <SliceAttachmentMultipartUpload
            buildUuid={buildUuid}
            orgUuid={currentUser!.organizationUuid}
            setNumFilesUploading={setNumFilesUploading}
            numFilesUploading={numFilesUploading}
          />
          <UploadedSliceFileAttachmentsTable buildUuid={buildUuid} />
          <UploadedBuildAttachmentsTable buildUuid={buildUuid} />
        </Box>
      }
      maxWidth="lg"
    />
  );
}

export default SliceFilesUploadModal;

const defaultFilters = {sortBy: {createdAt: 'DESC'}} as any;

export const UploadedSliceFileAttachmentsTable = ({buildUuid}: {buildUuid: string}) => {
  const sliceAttachmentList = useSelector(
    (state: RootState) => state.sliceAttachmentStore.list?.filter((attachment) => !attachment.deletedAt),
    isEqual
  );
  const buildUuidFilter = useMemo(() => ({buildUuid, excludeDeleted: true}), [buildUuid]);
  const [slicesDeleting, setSlicesDeleting] = useState<ISliceAttachmentGETResponse[]>();
  const build = useSelector(
    (state: RootState) =>
      state.buildStore.byId[buildUuid] || state.buildStore.list.find((build) => build.uuid === buildUuid)
  );

  const [selectedRows, setSelectedRows] = useState<ISliceAttachmentGETResponse[]>([]);

  // defines a function using the useCallback hook fro
  const useSliceAttachments = useCallback(() => {
    return sliceAttachmentList.map((sliceAttachment) => ({
      ...sliceAttachment,
      disableDelete: build ? isRunningBuildState(build.state) : false,
      onRowDelete: () => setSlicesDeleting([sliceAttachment]),
      tableData: {
        checked: Boolean(selectedRows.find((row) => row.uuid === sliceAttachment.uuid)),
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliceAttachmentList]);

  return (
    <Grid item xs={12} md={12} lg={12} style={{paddingTop: '12px'}}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5">Attached Slice Files</Typography>
        <Alert severity="info" style={{marginTop: '12px'}}>
          Please note that files do not have to be verified before continuing to the next step, and will continue to
          verify in the background.
        </Alert>
      </Grid>
      <GenericTable
        resourceType="sliceAttachment"
        filteringEnabled={false}
        permanentFilters={buildUuidFilter}
        defaultFilters={defaultFilters}
        useData={useSliceAttachments}
        minTableWidth="900px"
        onSelectionChange={(rows) => setSelectedRows(rows)}
        tableActions={[
          {
            tooltip: 'Remove All Selected Slice Files',
            icon: () => <Delete />,
            onClick: () => setSlicesDeleting(selectedRows),
          },
        ]}
      />
      <DeleteModal
        slicesDeleting={slicesDeleting}
        setSlicesDeleting={setSlicesDeleting}
        setSelectedRows={setSelectedRows}
        documentsDeleting={[]}
        setDocumentsDeleting={() => {}}
        setSelectedDocs={() => {}}
      />
    </Grid>
  );
};

const DeleteModal = ({
  slicesDeleting,
  setSlicesDeleting,
  setSelectedRows,
  documentsDeleting,
  setDocumentsDeleting,
  setSelectedDocs,
}: {
  slicesDeleting: ISliceAttachmentGETResponse[] | undefined;
  setSlicesDeleting: React.Dispatch<React.SetStateAction<ISliceAttachmentGETResponse[] | undefined>>;
  setSelectedRows: React.Dispatch<React.SetStateAction<ISliceAttachmentGETResponse[]>>;
  documentsDeleting: IBuildAttachmentsGETResponse[] | undefined;
  setDocumentsDeleting: React.Dispatch<React.SetStateAction<IBuildAttachmentsGETResponse[] | undefined>>;
  setSelectedDocs: React.Dispatch<React.SetStateAction<IBuildAttachmentsGETResponse[]>>;
}) => {
  const [deleting, setDeleting] = useState(false);

  const onDelete = async () => {
    if ((!slicesDeleting || slicesDeleting.length === 0) && (!documentsDeleting || documentsDeleting.length === 0))
      return;
    setDeleting(true);
    try {
      if (slicesDeleting && slicesDeleting.length > 0) {
        for (const attachment of slicesDeleting) {
          await sliceAttachmentByUuidDELETE(attachment.uuid);
        }
        setSlicesDeleting(undefined);
        setSelectedRows([]);
      }

      if (documentsDeleting && documentsDeleting.length > 0) {
        for (const document of documentsDeleting) {
          await buildAttachmentByUuidDELETE(document.uuid);
        }
        setDocumentsDeleting(undefined);
        setSelectedDocs([]);
      }
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <GenericDialog
        title="Delete Slice Attachments?"
        content={
          <>
            Are you sure you want to delete the following slice attachments?
            <br />
            {slicesDeleting &&
              slicesDeleting.map((attachment, index) => (
                <div key={attachment.uuid}>
                  <b>
                    <i>{attachment.filename}</i>
                  </b>
                  {index < slicesDeleting.length - 1 ? ', ' : ''}
                </div>
              ))}
            <br />
            All parts and associated resources derived from these slice files will also be deleted.
          </>
        }
        isOpen={!!slicesDeleting && slicesDeleting.length > 0}
        closeDialog={() => setSlicesDeleting(undefined)}
        onSuccess={onDelete}
        requestInProgress={deleting}
        confirmButtonProps={{endIcon: <Delete />}}
      />
      <GenericDialog
        title="Delete Build Attachments?"
        content={
          <>
            Are you sure you want to delete the following build attachments?
            <br />
            {documentsDeleting &&
              documentsDeleting.map((attachment, index) => (
                <div key={attachment.uuid}>
                  <b>
                    <i>{attachment.filename}</i>
                  </b>
                  {index < documentsDeleting.length - 1 ? ', ' : ''}
                </div>
              ))}
            <br />
          </>
        }
        isOpen={!!(documentsDeleting && documentsDeleting.length > 0)}
        closeDialog={() => setDocumentsDeleting(undefined)}
        onSuccess={onDelete}
        requestInProgress={deleting}
        confirmButtonProps={{endIcon: <Delete />}}
      />
    </>
  );
};

export const UploadedBuildAttachmentsTable = ({buildUuid}: {buildUuid: string}) => {
  const buildAttachmentList = useSelector(
    (state: RootState) => state.buildAttachmentStore.list?.filter((attachment) => !attachment.deletedAt),
    isEqual
  );

  const [documentsDeleting, setDocumentsDeleting] = useState<IBuildAttachmentsGETResponse[]>();

  const [selectedDocs, setSelectedDocs] = useState<IBuildAttachmentsGETResponse[]>([]);

  const useBuildAttachments = useCallback(() => {
    return buildAttachmentList.map((buildAttachment) => ({
      ...buildAttachment,
      disableDelete: true,
      onRowDelete: () => setDocumentsDeleting([buildAttachment]),
      tableData: {
        checked: Boolean(selectedDocs.find((row) => row.uuid === buildAttachment.uuid)),
      },
    }));
  }, [buildAttachmentList, selectedDocs]);

  const buildUuidFilter = useMemo(
    () => ({buildUuid, excludeDeleted: true, type: BuildAttachmentType.BuildAttachment}),
    [buildUuid]
  );

  return (
    <Grid item xs={12} md={12} lg={12} style={{paddingTop: '12px'}}>
      <GenericTable
        resourceType="buildAttachment"
        filteringEnabled={false}
        permanentFilters={buildUuidFilter}
        defaultFilters={defaultFilters}
        useData={useBuildAttachments}
        tableTitle="Attached Documents"
        onSelectionChange={(rows) => setSelectedDocs(rows)}
        minTableWidth="620px"
        tableActions={[
          {
            tooltip: 'Remove All Selected Documents',
            icon: () => <Delete />,
            onClick: () => setDocumentsDeleting(selectedDocs),
          },
        ]}
      />
      <DeleteModal
        slicesDeleting={[]}
        setSlicesDeleting={() => {}}
        setSelectedRows={() => {}}
        documentsDeleting={documentsDeleting}
        setDocumentsDeleting={setDocumentsDeleting}
        setSelectedDocs={setSelectedDocs}
      />
    </Grid>
  );
};

import React from 'react';
import {Typography} from '@material-ui/core';

import {renderDateTimeString} from '../../../../../src/utils/string';

import {EllipsisTextColumnTooltip} from './sharedColumns';
import {BuildEventType, IBuildEventTableRecord} from '@common/api/models/builds/IBuildEvent';

export const buildEventTableSchema = [
  {
    title: 'Message',
    field: 'message',
    render: (row: IBuildEventTableRecord) => (
      <EllipsisTextColumnTooltip>{getPrettyBuildEvent(row)}</EllipsisTextColumnTooltip>
    ),
    sorting: false,
  },
  {
    title: 'Date',
    field: 'date',
    defaultSort: 'desc',
    width: '180px',
    render: (row: IBuildEventTableRecord) => (
      <Typography>{renderDateTimeString('short', row.successTime || row.eventTime)}</Typography>
    ),
    sorting: false,
  },
];

export default buildEventTableSchema;

export function getPrettyBuildEvent(event: IBuildEventTableRecord) {
  const name = event.userName;
  const success = !!event.successTime;
  switch (event.type) {
    case BuildEventType.PROVISION:
      return success ? <>{name} provisioned the build</> : <>{name} attempted to provision the build</>;
    case BuildEventType.CALIBRATE:
      return success ? <>{name} started calibration</> : <>{name} attempted to calibrate</>;
    case BuildEventType.AUTOFOCUS:
      return success ? <>{name} started auto focusing</> : <>{name} attempted to start auto focusing</>;
    case BuildEventType.MANUAL_FOCUS:
      return success ? <>{name} started manual focusing</> : <>{name} attempted to start manual focusing</>;
    case BuildEventType.MONITOR:
      return success ? <>{name} started monitoring</> : <>{name} attempted to start monitoring</>;
    case BuildEventType.MONITOR_PAUSE:
      return success ? <>{name} stopped monitoring</> : <>{name} attempted to stop monitoring</>;
    case BuildEventType.MONITOR_RESUME:
      return success ? <>{name} resumed monitoring</> : <>{name} attempted to resume monitoring</>;
    case BuildEventType.MONITOR_COMPLETE:
      return success ? <>{name} completed the build</> : <>{name} attempted to complete the build</>;
  }
}

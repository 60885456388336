import React from 'react';
import Header from '../../components/organisms/Header';
import {Box} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

export default function OrganizationSettingsPage() {
  return (
    <React.Fragment>
      <Header
        helmet={`Organization Settings`}
        title={`Organization Settings`}
        breadcrumbs={[{title: 'Organization Settings', path: '/organizations/settings'}]}
      />

      <Box display="flex" justifyContent="center" width="100%">
        <Alert severity="info">
          The following security settings can be optionally enabled for your organization.
          <ul>
            <li>Two-factor authentication</li>
            <li>Password Rotation</li>
          </ul>
          If you wish to enable these, please get in touch via{' '}
          <a href="mailto:support@additiveassurance.com">support@additiveassurance.com</a>.
        </Alert>
      </Box>
    </React.Fragment>
  );
}

import {IBuildEventTableRecord} from '@common/api/models/builds/IBuildEvent';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import axios from 'axios';
import {QueryFilter} from '../../store/actions/liveUpdateStore';
import qs from 'qs';

export async function buildEventsAllGET(
  filter: QueryFilter<{buildUuid: string}>
): AjaxApiResult<IBuildEventTableRecord[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/buildEvents/' + (query && '?' + query));
  }, 'Could not fetch build events');
}

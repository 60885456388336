import {join, pick, flatten} from 'lodash';

import {Resource} from '@common/api/websocket/resourceMessage';

import createReducer from './createReducer';
import {
  FetchingState,
  LiveStoreActions,
  LiveStoreDeleteAction,
  LiveStoreFetchErrorAction,
  LiveStoreFinishFetchAction,
  LiveStoreInvalidateAction,
  LiveStoreLogLastWsConnAction,
  LiveStoreLogLastWsFilterAction,
  LiveStoreLogLastWsListConnAction,
  LiveStoreStartFetchAction,
  LiveStoreStartFetchActionFilter,
  LiveStoreState,
  LiveStoreUpdateAction,
} from '../model/liveUpdateStore';

export interface ReducerFunctions<T, Y extends LiveStoreState<T>> {
  fetchError(state: Y, action: LiveStoreFetchErrorAction<T>): Y;
  finishFetch(state: Y, action: LiveStoreFinishFetchAction<T>): Y;
  update(state: Y, action: LiveStoreUpdateAction<T>): Y;
  invalidate(state: Y, action: LiveStoreInvalidateAction<T>): Y;
  invalidateFetchHistory(state: Y, action: LiveStoreInvalidateAction<T>): Y;
  startFetch(state: Y, action: LiveStoreStartFetchActionFilter<T>): Y;
  logLastWSConn(state: Y, action: LiveStoreLogLastWsConnAction<T>): Y;
  delete(state: Y, action: LiveStoreDeleteAction<T>): Y;
  logLastWSFilter(state: Y, action: LiveStoreLogLastWsFilterAction<T>): Y;

  // List (table) reducers
  updateInList(state: Y, action: LiveStoreUpdateAction<T>): Y;
  insertInList(state: Y, action: LiveStoreUpdateAction<T>): Y;
  deleteInList(state: Y, action: LiveStoreDeleteAction<T>): Y;
  startListFetch(state: Y, action: LiveStoreStartFetchAction<T>): Y;
  finishListFetch(state: Y, action: LiveStoreFinishFetchAction<T>): Y;
  fetchListError(state: Y, action: LiveStoreFetchErrorAction<T>): Y;
  logLastWSListConn(state: Y, action: LiveStoreLogLastWsListConnAction<T>): Y;
  unsubscribeFromList(state: Y): Y;
  invalidateListHistory(state: Y): Y;
}

/**
 * Base class for creating live-store reducers
 * Build upon by inheriting this class & modifying returns from
 *
 * @param idField Primary key(s) of object T to use as store ID
 * @param resourceType Resource type identifier
 * @param initialState An initial state
 * @param extraReducers Optional additional reducers that occur either before or after the default ones.
 */
export class LiveStoreReducers<T, Y extends LiveStoreState<T>> {
  suffix: string; // Resource
  idFields: (keyof T)[]; // Primary key(s) of object T to use as store ID

  constructor(idField: keyof T | (keyof T)[], resourceType: Resource) {
    this.suffix = Resource[resourceType];
    this.idFields = Array.isArray(idField) ? idField : [idField];
  }

  /**
   * Returns live-store reducers; class method for ease-of-use.
   * @param idField Primary key(s) of object T to use as store ID
   * @param resourceType Resource type identifier
   * @param initialState An initial state
   */
  static createReducer<T, Y extends LiveStoreState<T>>(
    idField: keyof T | (keyof T)[],
    resourceType: Resource,
    initialState: Y
  ) {
    return new this(idField, resourceType).createReducer(initialState);
  }

  getReducerFunctions(
    getStoreId: (payload: T) => string,
    getStoreIndex: (payload: T, store: LiveStoreState<T>) => number
  ): ReducerFunctions<T, Y> {
    function deleteResourceById(resource: T, state: Y) {
      const storeId = getStoreId(resource);
      const {[storeId]: _deletedResource, ...newStore} = state.byId;
      return {
        ...state,
        listFetchHistory: [],
        byId: newStore,
      };
    }

    function deleteResourceInList(resource: T, state: Y) {
      const storeIndex = getStoreIndex(resource, state);
      const storeId = getStoreId(resource);
      const {[storeId]: _T, ...newById} = state.byId;

      if (storeIndex < 0) return {...state, byId: newById};

      const newList = [...state.list];
      newList.splice(storeIndex, 1);

      return {
        ...state,
        fetchHistory: [],
        listFetchHistory: [],
        byId: newById,
        list: newList,
        listTotal: state.listTotal ? state.listTotal - 1 : null,
      };
    }

    return {
      update(state: Y, action: LiveStoreUpdateAction<T>): Y {
        const storeId = getStoreId(action.payload);
        const current = state.byId[storeId] || {};

        // Resource was soft deleted
        // @ts-ignore
        if (!current.deletedAt && !!action.payload.deletedAt) {
          return deleteResourceById(action.payload, state);
        }

        return {
          ...state,
          listFetchHistory: [],
          byId: {...state.byId, [storeId]: {...current, ...action.payload}},
        };
      },

      delete(state: Y, action: LiveStoreDeleteAction<T>): Y {
        return deleteResourceById(action.payload, state);
      },

      startFetch(state: Y, action: LiveStoreStartFetchActionFilter<T>): Y {
        return {
          ...state,
          fetched: action.payload.setLoading ? FetchingState.Fetching : state.fetched,
          fetchHistory: [...state.fetchHistory, {filters: action.payload.filter}],
        };
      },

      finishFetch(state: Y, action: LiveStoreFinishFetchAction<T>): Y {
        const total = action.payload.count ?? state.total;
        const data = flatten([action.payload.data]);
        const filters = action.payload.filters;

        const changes: {[key: string]: T} = {};
        for (const c of data) {
          const storeId = getStoreId(c);
          changes[storeId as string] = c;
        }

        return {
          ...state,
          total: total,
          fetchHistory: [...state.fetchHistory, {filters, data, total}],
          fetched: FetchingState.Fetched,
          byId: {...state.byId, ...changes},
          errorMessage: undefined,
        };
      },

      fetchError(state: Y, action: LiveStoreFetchErrorAction<T>): Y {
        return {
          ...state,
          fetched: FetchingState.Error,
          errorMessage: action.payload,
        };
      },

      invalidate(state: Y, _action: LiveStoreInvalidateAction<T>): Y {
        return {
          ...state,
          fetched: FetchingState.None,
        };
      },

      invalidateFetchHistory(state: Y, _action: LiveStoreInvalidateAction<T>): Y {
        return {
          ...state,
          fetchHistory: [],
        };
      },

      logLastWSFilter(state: Y, action: LiveStoreLogLastWsFilterAction<T>): Y {
        return {
          ...state,
          lastWsFilter: action.payload,
        };
      },

      logLastWSConn(state: Y, action: LiveStoreLogLastWsConnAction<T>): Y {
        // Close existing for new connection
        if (state.lastWsConn && state.lastWsConn !== action.payload) {
          state.lastWsConn.close();
        }
        return {
          ...state,
          lastWsConn: action.payload, // Override with new connection
        };
      },

      //*** LIST (TABLE) REDUCERS ***/
      updateInList(state: Y, action: LiveStoreUpdateAction<T>): Y {
        const storeIndex = getStoreIndex(action.payload, state);
        const storeId = getStoreId(action.payload);
        const current = state.byId[storeId] || {};
        const newById = {
          ...state.byId,
          [storeId]: {...current, ...action.payload},
        };

        if (storeIndex < 0) return {...state, byId: newById};

        const newList = [...state.list];
        newList[storeIndex] = {...newList[storeIndex], ...action.payload};

        // Resource was soft deleted
        // @ts-ignore
        if (!current.deletedAt && !!action.payload.deletedAt) {
          return deleteResourceInList(action.payload, state);
        }

        return {
          ...state,
          fetchHistory: [],
          listFetchHistory: [],
          byId: newById,
          list: newList,
        };
      },

      insertInList(state: Y, action: LiveStoreUpdateAction<T>): Y {
        const storeId = getStoreId(action.payload);
        let list = state.list;
        let listTotal = state.listTotal || null;

        if (!state.shouldCountWsInserts) {
          list = [action.payload, ...state.list];
          listTotal = listTotal ? listTotal + 1 : null;
        }

        return {
          ...state,
          fetchHistory: [],
          listFetchHistory: [],
          byId: {...state.byId, [storeId]: action.payload},
          list,
          listTotal,
          listUpdatesCount: state.shouldCountWsInserts ? state.listUpdatesCount + 1 : 0,
        };
      },

      deleteInList(state: Y, action: LiveStoreDeleteAction<T>): Y {
        return deleteResourceInList(action.payload, state);
      },

      startListFetch(state: Y, _action: LiveStoreStartFetchAction<T>): Y {
        return {
          ...state,
          listFetched: FetchingState.Fetching,
        };
      },

      finishListFetch(state: Y, action: LiveStoreFinishFetchAction<T>): Y {
        const total = action.payload.count ?? state.total;
        const list = flatten([action.payload.data]);
        const filters = action.payload.filters;

        return {
          ...state,
          listTotal: total,
          list,
          listFilters: filters,
          listFetched: FetchingState.Fetched,
          listFetchHistory: [...state.listFetchHistory, {filters, list, total}],
          listErrorMessage: undefined,
          listUpdatesCount: 0,
        };
      },

      fetchListError(state: Y, action: LiveStoreFetchErrorAction<T>): Y {
        return {
          ...state,
          listFetched: FetchingState.Error,
          listErrorMessage: action.payload, // TODO this payload is varying type
        };
      },

      logLastWSListConn(state: Y, action: LiveStoreLogLastWsListConnAction<T>): Y {
        // Close existing for new connection
        if (state.lastListWsConn && state.lastListWsConn !== action.payload.conn) {
          state.lastListWsConn.close();
        }

        return {
          ...state,
          lastListWsConn: action.payload.conn, // Override with new connection
          shouldCountWsInserts: action.payload.shouldCountWsInserts,
        };
      },

      unsubscribeFromList(state: Y): Y {
        // Close existing connection
        if (state.lastListWsConn) {
          state.lastListWsConn.close();
        }

        return {
          ...state,
          list: [],
          listFilters: null,
          listTotal: null,
          listFetched: FetchingState.None,
          listErrorMessage: undefined,
          lastListWsConn: null,
        };
      },

      invalidateListHistory(state: Y): Y {
        // Close existing connection
        if (state.lastListWsConn) {
          state.lastListWsConn.close();
        }

        return {
          ...state,
          listFetchHistory: [],
        };
      },
    };
  }

  bindReducers() {
    const getStoreId = (payload: Object): string => join(Object.values(pick(payload, this.idFields)), '-');

    const getStoreIndex = (payload: Object, store: LiveStoreState<T>): number => {
      return store.list.findIndex((resource: T) => {
        return (
          join(Object.values(pick(payload, this.idFields)), '-') ===
          join(Object.values(pick(resource, this.idFields)), '-')
        );
      });
    };

    // @ts-ignore
    const reducers = this.getReducerFunctions(getStoreId, getStoreIndex);

    return {
      [LiveStoreActions.UPDATE + this.suffix]: reducers.update,
      [LiveStoreActions.DELETE + this.suffix]: reducers.delete,
      [LiveStoreActions.START_FETCH + this.suffix]: reducers.startFetch,
      [LiveStoreActions.FINISH_FETCH + this.suffix]: reducers.finishFetch,
      [LiveStoreActions.FETCH_ERROR + this.suffix]: reducers.fetchError,
      [LiveStoreActions.INVALIDATE + this.suffix]: reducers.invalidate,
      [LiveStoreActions.INVALIDATE_FETCH_HISTORY + this.suffix]: reducers.invalidateFetchHistory,
      [LiveStoreActions.LOG_LAST_WS_FILTER + this.suffix]: reducers.logLastWSFilter,
      [LiveStoreActions.LOG_LAST_WS_CONN + this.suffix]: reducers.logLastWSConn,

      [LiveStoreActions.UPDATE_LIST + this.suffix]: reducers.updateInList,
      [LiveStoreActions.INSERT_LIST + this.suffix]: reducers.insertInList,
      [LiveStoreActions.DELETE_LIST + this.suffix]: reducers.deleteInList,
      [LiveStoreActions.START_LIST_FETCH + this.suffix]: reducers.startListFetch,
      [LiveStoreActions.FINISH_LIST_FETCH + this.suffix]: reducers.finishListFetch,
      [LiveStoreActions.FETCH_LIST_ERROR + this.suffix]: reducers.fetchListError,
      [LiveStoreActions.LOG_LAST_WS_LIST_CONN + this.suffix]: reducers.logLastWSListConn,
      [LiveStoreActions.UNSUBSCRIBE_FROM_LIST + this.suffix]: reducers.unsubscribeFromList,
      [LiveStoreActions.INVALIDATE_LIST_HISTORY + this.suffix]: reducers.invalidateListHistory,
    };
  }

  createReducer(initialState: Y) {
    const reducers = this.bindReducers();

    return createReducer<LiveStoreState<T>>(initialState, reducers);
  }
}

import React, {useEffect, useState} from 'react';
import {TextField, Grid, InputAdornment, Typography} from '@material-ui/core';
import {humanize} from 'inflection';

function parseValue(value: string) {
  return value.split('.')[1]?.length > 5 ? Math.floor(parseFloat(value) * 1e5) / 1e5 : parseFloat(value);
}

export function PlanarTupleField({
  field,
  value,
  onChange,
  min,
  max,
  unit_x,
  unit_y,
  setError,
  disabled,
}: {
  field: string;
  value: [number, number];
  onChange: (newValue: [number, number] | null) => void;
  min: number;
  max: number;
  unit_x: string;
  unit_y: string;
  error: boolean;
  setError: (error: boolean) => void;
  disabled: boolean;
}) {
  const [internalValue_x, setInternalValue_x] = useState(`${parseValue(`${value[0]}`)}`);
  const [internalValue_y, setInternalValue_y] = useState(`${parseValue(`${value[1]}`)}`);

  const [internalXError, setInternalXError] = useState(false);
  const [internalYError, setInternalYError] = useState(false);

  useEffect(() => {
    if (value !== undefined) {
      if (`${value[0]}` !== internalValue_x) {
        setInternalValue_x(`${parseValue(`${value[0]}`)}`);
      }
      if (`${value[1]}` !== internalValue_y) {
        setInternalValue_y(`${parseValue(`${value[1]}`)}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (internalXError || internalYError) {
      setError(true);
    } else {
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalXError, internalYError]);

  useEffect(() => {
    checkErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue_x, internalValue_y]);

  function handleChange(newValue: string, axis: 'x' | 'y') {
    if (newValue === '') {
      if (axis === 'x') {
        setInternalValue_x('');
        setInternalXError(true);
      } else {
        setInternalValue_y('');
        setInternalYError(true);
      }
      return;
    } else {
    }

    let value = parseValue(newValue);

    const stringValue = newValue;
    if (axis === 'x') {
      setInternalValue_x(isNaN(value) ? '' : stringValue);
    } else {
      setInternalValue_y(isNaN(value) ? '' : stringValue);
    }
    onChange([
      parseValue(axis === 'x' ? newValue : internalValue_x),
      parseValue(axis === 'y' ? newValue : internalValue_y),
    ]);
  }

  function checkErrors() {
    const floatValue_x = Number(internalValue_x);
    const floatValue_y = Number(internalValue_y);

    // Validate x
    if (internalValue_x === '' || isNaN(floatValue_x) || floatValue_x < min || floatValue_x > max) {
      setInternalXError(true);
    } else {
      setInternalXError(false);
    }

    // Validate y
    if (internalValue_y === '' || isNaN(floatValue_y) || floatValue_y < min || floatValue_y > max) {
      setInternalYError(true);
    } else {
      setInternalYError(false);
    }
  }

  return (
    <Grid container item xs={12} sm={12} md={12} spacing={3}>
      <Grid item>
        <Typography variant="subtitle2">{humanize(field)}</Typography>
      </Grid>
      <Grid item container xs={12} sm={12} md={12} spacing={2} direction="row">
        <Grid item>
          <TextField
            fullWidth
            type={'number'}
            disabled={disabled}
            size="small"
            error={internalXError}
            variant="outlined"
            onBlur={checkErrors}
            name="x"
            placeholder="x"
            label="x"
            helperText={`A number between ${min}-${max}`}
            value={internalValue_x}
            onChange={(e) => {
              // "[.]" passes the regex but get's converted to NaN. If "." is entered, send "0." instead
              const newValue = e.target.value;
              if (newValue !== '.' && isNaN(newValue as any)) {
                handleChange('', 'x');
              }

              newValue === '.' ? handleChange('0.', 'x') : handleChange(newValue, 'x');
            }}
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit_x}</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            type={'number'}
            disabled={disabled}
            size="small"
            error={internalYError}
            variant="outlined"
            onBlur={checkErrors}
            name="y"
            placeholder="y"
            label="y"
            helperText={<>{`A number between ${min}-${max}`}</>}
            value={internalValue_y}
            onChange={(e) => {
              // "." passes the regex but get's converted to NaN. If "." is entered, send "0." instead
              const newValue = e.target.value;
              if (newValue !== '.' && isNaN(newValue as any)) {
                handleChange('', 'y');
              }

              newValue === '.' ? handleChange('0.', 'y') : handleChange(newValue, 'y');
            }}
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
              },
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">{unit_y}</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PlanarTupleField;

export function makeBackgroundTransparent(img: HTMLImageElement, r: number, g: number, b: number) {
  if (!img.width) {
    return img;
  }
  const transparentColor = {
    r,
    g,
    b,
  };

  // create a source canvas. This is our pixel source
  const srcCanvas = document.createElement('canvas');
  srcCanvas.width = img.width;
  srcCanvas.height = img.height;

  // append the canvas elements to the container
  document.getElementById('container')?.appendChild(srcCanvas);

  // get context to work with
  const srcContext = srcCanvas.getContext('2d')!;

  // draw the loaded image on the source canvas
  srcContext.drawImage(img, 0, 0);

  // read pixels from source
  const pixels = srcContext.getImageData(0, 0, img.width, img.height);

  // iterate through pixel data (1 pixels consists of 4 ints in the array)
  for (let i = 0, len = pixels.data.length; i < len; i += 4) {
    const r = pixels.data[i];
    const g = pixels.data[i + 1];
    const b = pixels.data[i + 2];

    // if the pixel matches our transparent color, set alpha to 0
    if (r === transparentColor.r && g === transparentColor.g && b === transparentColor.b) {
      pixels.data[i + 3] = 0;
    }
  }

  srcCanvas.remove();

  srcContext.clearRect(0, 0, srcCanvas.width, srcCanvas.height);
  // write pixel data to destination context
  srcContext.putImageData(pixels, 0, 0);
  const result = new Image();
  result.src = srcCanvas.toDataURL();
  return result;
}

export interface ImageFilter {
  brightness: number; // 0 to 400 %:
  contrast: number; // 0 to 200 %
  gammaAmplitude: number; // 0 to 5
  gammaExponent: number; // 0 to 5
  saturation: number; //0 to 100%
  imageSmoothing: boolean;
}

export const defaultImageFilters: ImageFilter = {
  brightness: 100,
  contrast: 100,
  gammaAmplitude: 1,
  gammaExponent: 1,
  saturation: 100,
  imageSmoothing: false,
};

// Functions: https://developer.mozilla.org/en-US/docs/Web/CSS/filter#functions
export const constructImageFilter = ({brightness, contrast, saturation}: ImageFilter) =>
  `brightness(${
    brightness <= 100 ? brightness : 100 + ((brightness - 100) / 100) * 400
  }%) contrast(${contrast}%) saturate(${saturation <= 100 ? saturation : 100 + ((saturation - 100) / 100) * 400}%)`;

import {IMachine} from './IMachine';

export function getMachineCalibFile(machine: IMachine) {
  if (!machine) return;
  return MACHINE_DETAILS[machine.manufacturer]?.[machine.model]?.calibFile;
}

export function getMachineImage(machine: IMachine) {
  if (!machine) return;

  return MACHINE_DETAILS[machine.manufacturer]?.[machine.model]?.image;
}

export function getAllModels(includeManufacturer = false) {
  return Object.entries(MACHINE_DETAILS)
    .map(([manufacturer, manufacturerDetails]) =>
      Object.keys(manufacturerDetails).map((model) => (includeManufacturer ? `${manufacturer} ${model}` : model))
    )
    .reduce((allModels, models) => [...allModels, ...models]);
}

// Must match the filenames as defined in /client/public/files/
export enum CalibFile {
  C250 = 'calib_31x31_sq225mm_quadID-Ren.stl',
  C250_EOS = 'calib_31x31_sq225mm_quadID-EOS.stl',
  C280 = 'calib_31x31_sq225mm_quadID-Ren.stl',
  D = 'calib_31x37_SisRound_quadID.stl',
  E = 'calib_35x38_500L_quadID.stl',
  F = 'calib_RBV.STL',
  G = 'calib_420x420_AI_Metalfab.STL',
  H = '',
}

/**
 * enum here for Legacy Purposes
 */
export enum CalibPattern {
  C250 = 'CalibPatternC-250',
  C280 = 'CalibPatternC-280',
  D = 'CalibPatternD',
  E = 'CalibPatternE',
  F = 'CalibPatternF',
  G = 'CalibPatternG',
  H = 'CalibPatternH',
}

// Taken from https://docs.google.com/spreadsheets/d/1wOlZo-OSpiiwOkDZp-_xOyCuxkUeVTD_0sWJzH6AomE/edit#gid=0
// Or ask Marten for the latest version
export const MACHINE_DETAILS: {
  [manufacturer: string]: {
    [model: string]: {
      calibFile: CalibFile;
      image: string;
    };
  };
} = {
  EOS: {
    M280: {
      calibFile: CalibFile.C250_EOS,
      image: 'EOS_M280.png',
    },
    M290: {
      calibFile: CalibFile.C250_EOS,
      image: 'EOS_M290.png',
    },
    M400: {
      calibFile: CalibFile.G,
      image: 'EOS_M400.png',
    },
  },
  Renishaw: {
    AM250: {
      calibFile: CalibFile.C250,
      image: 'RENISHAW_AM250.png',
    },
    AM400: {
      calibFile: CalibFile.C250,
      image: 'RENISHAW_AM400.png',
    },
    'RenAM 500': {
      calibFile: CalibFile.C250,
      image: 'RENISHAW_AM500_Q.png',
    },
    'RenAM 500 RBV': {
      calibFile: CalibFile.F,
      image: 'RENISHAW_AM500_Q.png',
    },
  },
  'SLM Solutions': {
    '250HL': {
      calibFile: CalibFile.C250,
      image: 'SLM_250HL_gen2.png',
    },
    '280': {
      calibFile: CalibFile.C280,
      image: 'SLM_280.png',
    },
    '280HL': {
      calibFile: CalibFile.C280,
      image: 'SLM_280HL.png',
    },
    '500': {
      calibFile: CalibFile.E,
      image: 'SLM_500.png',
    },
    '500HL': {
      calibFile: CalibFile.E,
      image: 'SLM_500HL.png',
    },
  },
  '3D Systems': {
    'ProX DMP 320': {
      calibFile: CalibFile.C250,
      image: 'ProX_DMP_320.png',
    },
  },
  Trumpf: {
    'TruPrint 3000': {
      calibFile: CalibFile.D,
      image: 'Trumpf_TruPrint_3000.png',
    },
  },
  Sisma: {
    'MYSINT 300': {
      calibFile: CalibFile.D,
      image: 'SISMA_Mysint_300.png',
    },
  },
  'Concept Laser': {
    'M2 Series 4': {
      calibFile: CalibFile.C250,
      image: 'M2_Series_4.png',
    },
    'M2 Series 5': {
      calibFile: CalibFile.C250,
      image: 'M2_Series_5.png',
    },
  },
  'Additive Industries': {
    'MetalFAB G2': {
      calibFile: CalibFile.G,
      image: 'Additive_Industries_MetalFAB_G2.png',
    },
    'MetalFAB G2 (experimental)': {
      calibFile: CalibFile.H,
      image: 'Additive_Industries_MetalFAB_G2.png',
    },
  },
};

/**
 * Maps a given calibration pattern name to its corresponding sensor profile.
 *
 * @param pattern - The name of the calibration pattern to map.
 * @returns An object containing the Pattern Object compatible with Sensor Profiles >= v1.11.0
 *
 * Derived from https://github.com/addassure/AA_midrig/blob/dev/primary/configs/PARAMETERS_README.md
 *
 */
export function mapCalibrationPatternToSensorProfile(pattern: CalibPattern) {
  const patterns = {
    [CalibPattern.C250]: {
      grid_dimensions: [31, 31],
      build_plate_dimensions: [250, 250],
      separation: 7.5,
      key_features: [
        ['square', [7, 7]],
        ['triangle', [8, 8]],
        ['square', [7, 23]],
        ['triangle', [8, 22]],
        ['square', [23, 7]],
        ['triangle', [22, 8]],
        ['square', [23, 23]],
        ['triangle', [22, 22]],
      ],
    },
    [CalibPattern.C280]: {
      grid_dimensions: [31, 31],
      build_plate_dimensions: [280, 280],
      separation: 7.5,
      key_features: [
        ['square', [7, 7]],
        ['triangle', [8, 8]],
        ['square', [7, 23]],
        ['triangle', [8, 22]],
        ['square', [23, 7]],
        ['triangle', [22, 8]],
        ['square', [23, 23]],
        ['triangle', [22, 22]],
      ],
    },
    [CalibPattern.D]: {
      grid_dimensions: [37, 31],
      build_plate_dimensions: [300, 300],
      separation: 7.5,
      key_features: [
        ['square', [10, 7]],
        ['triangle', [11, 8]],
        ['square', [10, 23]],
        ['triangle', [11, 22]],
        ['square', [26, 7]],
        ['triangle', [25, 8]],
        ['square', [26, 23]],
        ['triangle', [25, 22]],
      ],
    },
    [CalibPattern.E]: {
      grid_dimensions: [35, 38],
      build_plate_dimensions: [280, 500],
      separation: 7.5,
      key_features: [
        ['square', [9, 14]],
        ['triangle', [10, 15]],
        ['square', [9, 30]],
        ['triangle', [10, 29]],
        ['square', [25, 14]],
        ['triangle', [24, 15]],
        ['square', [25, 30]],
        ['triangle', [24, 29]],
        ['centre', [17, 6]],
      ],
    },
    [CalibPattern.F]: {
      grid_dimensions: [31, 31],
      build_plate_dimensions: [250, 250],
      separation: 7.5,
      key_features: [
        ['square', [7, 7]],
        ['triangle', [8, 8]],
        ['square', [7, 23]],
        ['triangle', [8, 22]],
        ['square', [23, 7]],
        ['triangle', [22, 8]],
        ['square', [23, 23]],
        ['triangle', [22, 22]],
      ],
    },
    [CalibPattern.G]: {
      grid_dimensions: [31, 31],
      build_plate_dimensions: [250, 250],
      separation: 7.5,
      key_features: [
        ['square', [7, 7]],
        ['triangle', [8, 8]],
        ['square', [7, 23]],
        ['triangle', [8, 22]],
        ['square', [23, 7]],
        ['triangle', [22, 8]],
        ['square', [23, 23]],
        ['triangle', [22, 22]],
      ],
    },
    [CalibPattern.H]: {
      grid_dimensions: [41, 41],
      build_plate_dimensions: [420, 420],
      separation: 10,
      key_features: [
        ['square', [9, 11]],
        ['triangle', [8, 10]],
        ['square', [9, 29]],
        ['triangle', [8, 30]],
        ['square', [31, 11]],
        ['triangle', [32, 10]],
        ['square', [31, 29]],
        ['triangle', [32, 30]],
      ],
    },
  };

  return patterns[pattern];
}

import React, {useState} from 'react';
import CTAlignmentViewport from '../CTAlignmentViewport';
import {CommonCTStepProps} from './CTSteps';
import {Box, Button, CircularProgress} from '@material-ui/core';
import {ArrowForward} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import {RootState} from '../../../store/reducers';
import {ctReportAlignmentPATCH} from '../../../api/ajax/ctReport';
import {useContainerHeight} from '../../../utils/utilHooks';
import styled from 'styled-components';

function AlignmentStep({currentCTReport}: CommonCTStepProps) {
  const {height, setContainerRef} = useContainerHeight();
  const ctReportStore = useSelector((state: RootState) => state.ctReportStore);
  const [requestingAlignment, setRequestingAlignment] = useState(false);

  const onAlignmentSubmit = async () => {
    setRequestingAlignment(true);
    const transform = ctReportStore.viewportTransform;
    await ctReportAlignmentPATCH(currentCTReport.uuid, transform.elements);
    setRequestingAlignment(false);
  };

  return (
    <Box height="100%" minHeight="450px">
      <Container ref={setContainerRef}>
        <CTAlignmentViewport currentReport={currentCTReport} stageHeight={height ? height - 14 : height} />
      </Container>
      <Box display="flex" flexDirection="row-reverse">
        <Button variant="contained" color="primary" onClick={onAlignmentSubmit} disabled={requestingAlignment}>
          Process Aligned CT Data{' '}
          {requestingAlignment ? (
            <CircularProgress size={20} style={{marginLeft: '12px'}} />
          ) : (
            <ArrowForward style={{marginLeft: '12px'}} />
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default AlignmentStep;

const Container = styled.div`
  padding-bottom: 14px;
  height: calc(100% - 37px);
`;

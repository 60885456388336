import * as React from 'react';
import {Card, Grid} from '@material-ui/core';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {useSelector} from 'react-redux';
import {RootState} from 'client/src/store/reducers';
import GenericTable from '../Table/GenericTable';

export function BuildEventsCard({buildUuid}: {buildUuid: string}) {
  const isSmall = useSmallScreenSize();

  const useBuildEvents = () => {
    const events = useSelector((state: RootState) => state.buildEventStore.list);

    return events;
  };

  return (
    <Card style={{padding: isSmall ? '15px' : '30px', height: '100%'}}>
      <Grid container spacing={1} style={{margin: '0'}} direction="column">
        <Grid item>
          <GenericTable
            resourceType="buildEvent"
            filteringEnabled={false}
            useData={useBuildEvents}
            tableTitle={'Build Updates'}
            permanentFilters={{buildUuid}}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

import {AnalysisType3D, CTDefectType3D} from '@common/api/models/builds/data/defects/IDefect';
import {transformInterval} from '../../../../../utils/MathUtils';
import {Color, Scene} from 'three';
import {useCallback, useEffect} from 'react';
import {BufferGeometry} from 'three';
import {View3DViewportParams} from '../View3DViewport';
import {PartPointClouds} from '../PartPointCloud';
import {toggleUse3DPoints, updateNumPointsDisplayed, rotatePointCloud} from '../viewportFunctions/control';
import {
  updateModelOpacity,
  updatePointCloudHighlight,
  updatePointColourOverride,
  updatePointSize,
} from '../viewportFunctions/decoration';
import {
  toggleSimilarityGeometry,
  toggleSimilarityRotationColours,
  toggleSimilarityTransparency,
} from '../viewportFunctions/similarity';
import {
  CTFiltersMap,
  ExplicitPoint,
  redBlueDivergentColourMap,
  SimilarityPoint,
  turboColourMap,
  View3DState,
} from '../types/pointCloudTypes';

function shouldDisplayCTPoint(
  point: ExplicitPoint & Partial<SimilarityPoint>,
  ctFilters: CTFiltersMap,
  activeDefectId: number | null | undefined
) {
  if (!!activeDefectId) {
    if (point.defectId !== activeDefectId) return false;
    return true;
  }

  const filters = Object.entries(ctFilters || {});

  for (const [key, {min, max}] of filters) {
    const filterKey = key as CTDefectType3D;
    if (!point[filterKey]) continue;

    if (min && point[filterKey]! < min) return false;
    if (max && point[filterKey]! > max) return false;
  }

  return true;
}

/**
 * Custom hook for handling point cloud effects in the 3D viewport.
 *
 * @param pointClouds - The point clouds to be rendered.
 * @param params - The viewport parameters.
 * @param scene - The 3D scene.
 * @param renderScene - A function to render the scene.
 * @param viewportState - The state of the 3D viewport.
 * @param geometry - Optional buffer geometry for the point clouds.
 * @param loadedLayers - Optional loaded layer images.
 * @param fetchLayerData - Optional function to fetch layer data.
 * @returns The loading state of the layer images.
 */
export function usePointCloudEffects(
  pointClouds: PartPointClouds,
  params: View3DViewportParams,
  scene: Scene,
  renderScene: () => void,
  viewportState: View3DState,
  geometry?: BufferGeometry
) {
  const numPointCloudsLoaded = pointClouds.numPointClouds;

  const getBlockColour = useCallback(
    (point: ExplicitPoint & Partial<SimilarityPoint>) => {
      const selectedType = Object.keys(params.selectedAnalysisTypes)[
        Object.values(params.selectedAnalysisTypes).indexOf(true)
      ] as AnalysisType3D;
      const {min, max} = params.analysisTypeSizes![selectedType];
      const limit = Math.max(Math.abs(min ?? 0), Math.abs(max ?? 0));
      const isASIM = selectedType === AnalysisType3D.ASIM;
      const currentColourMap = isASIM ? turboColourMap : redBlueDivergentColourMap;
      if (point.similarityCoefficient) {
        let transformation, colour;
        if (params.comparisonScaling) {
          if (isASIM) {
            transformation = transformInterval([min!, max!], [0, 99]);
          } else {
            transformation = transformInterval([-limit, limit], [0, 199]);
          }
        } else {
          if (isASIM) {
            transformation = transformInterval([0, 100], [0, 99]);
          } else {
            transformation = transformInterval([-100, 100], [0, 199]);
          }
        }
        colour = currentColourMap[Math.floor(transformation(point.similarityCoefficient))];

        return new Color(colour[0] / 255, colour[1] / 255, colour[2] / 255);
      }

      // Default to 0 on Colourmap if no Similarity Coefficient found

      const colour = redBlueDivergentColourMap[100];
      return new Color(colour[0] / 255, colour[1] / 255, colour[2] / 255);
    },
    [params.analysisTypeSizes, params.comparisonScaling, params.selectedAnalysisTypes]
  );

  const shouldDisplayfn = useCallback(
    (point: ExplicitPoint & Partial<SimilarityPoint>, analysisType: AnalysisType3D) => {
      const {min: minPointSize, max: maxPointSize} = params.pointSizeCutoff || {};
      const {min: minAnalysisAmount, max: maxAnalysisAmount} = params.analysisAmountCutoff || {};
      const {min: minDefectArea, max: maxDefectArea} = params.defectAreaFilter || {};

      if (minPointSize && point.scale < minPointSize) return false;
      if (maxPointSize && point.scale > maxPointSize) return false;
      if (minDefectArea && point.defectArea && point.defectArea < minDefectArea) return false;
      if (maxDefectArea && point.defectArea && point.defectArea > maxDefectArea) return false;

      if (point.similarityCoefficient !== undefined) {
        if (minAnalysisAmount && point.similarityCoefficient < minAnalysisAmount) return false;
        if (maxAnalysisAmount && point.similarityCoefficient > maxAnalysisAmount) return false;
      }

      if (params.isCtResults && params.ctFilters && analysisType === AnalysisType3D.Pore) {
        return shouldDisplayCTPoint(point, params.ctFilters, params.activeDefectId);
      }

      return true;
    },
    [
      params.pointSizeCutoff,
      params.analysisAmountCutoff,
      params.defectAreaFilter,
      params.isCtResults,
      params.ctFilters,
      params.activeDefectId,
    ]
  );

  useEffect(() => {
    toggleUse3DPoints(params, pointClouds, scene, renderScene, geometry);
    // eslint wants the dependency `params`. We don't need the models to reload
    // when any params change, so ignore.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.use3DPoints, renderScene]);

  useEffect(() => {
    toggleSimilarityTransparency(params, pointClouds, renderScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.isTransparent, renderScene]);

  useEffect(() => {
    updateNumPointsDisplayed(params.pointLimit, pointClouds, numPointCloudsLoaded, renderScene);
  }, [params.pointLimit, pointClouds, numPointCloudsLoaded, renderScene]);

  useEffect(() => {
    updatePointSize(
      params.pointSize,
      pointClouds,
      renderScene,
      shouldDisplayfn,
      params.analysisTypeSizes ? getBlockColour : undefined
    );
  }, [
    params.pointSize,
    params.analysisTypeSizes,
    params.defectAreaFilter,
    pointClouds,
    getBlockColour,
    shouldDisplayfn,
    renderScene,
    pointClouds.numPointClouds,
  ]);

  useEffect(() => {
    updateModelOpacity(params.modelOpacity, pointClouds, renderScene);
  }, [params.modelOpacity, pointClouds, renderScene]);

  useEffect(() => {
    updatePointColourOverride(
      params.overridePointColour,
      params.overrideColour,
      pointClouds,
      params.use3DPoints,
      renderScene,
      params.overrideAnalysisTypeColors,
      params.analysisTypeColorOverrides
    );
  }, [
    params.overrideColour,
    params.overridePointColour,
    pointClouds,
    params.use3DPoints,
    renderScene,
    params.overrideAnalysisTypeColors,
    params.analysisTypeColorOverrides,
    pointClouds.numPointClouds,
  ]);

  useEffect(() => {
    updatePointCloudHighlight(
      params.hoveredPartUuid,
      params.overridePointColour,
      params.overrideColour,
      pointClouds,
      params.use3DPoints,
      renderScene,
      params.overrideAnalysisTypeColors,
      params.analysisTypeColorOverrides
    );
  }, [
    params.hoveredPartUuid,
    params.overrideColour,
    params.overridePointColour,
    pointClouds,
    params.use3DPoints,
    renderScene,
    params.overrideAnalysisTypeColors,
    params.analysisTypeColorOverrides,
  ]);

  useEffect(() => {
    toggleSimilarityGeometry(params, pointClouds, renderScene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sourceGeometryEnabled, params.targetGeometryEnabled, pointClouds, renderScene]);

  useEffect(() => {
    if (viewportState === 'viewing' && params.rotation && Object.keys(params.rotation).length !== 0) {
      rotatePointCloud(params, pointClouds, renderScene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.rotation, params.selectedParts, viewportState, pointClouds, pointClouds.numPointClouds, renderScene]);

  useEffect(() => {
    if (params.centerAllParts && viewportState === 'viewing') {
      toggleSimilarityRotationColours(params, pointClouds, renderScene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportState, params.centerAllParts, params.selectedParts, pointClouds, renderScene]);
}

import {CircularProgress, Tooltip, Typography} from '@material-ui/core';
import {Add, Close} from '@material-ui/icons';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {IPartModelAttachmentGETResponse} from '@common/api/models/attachments/IPartModelAttachment';

import {RootState} from '../../../../store/reducers';
import {objMapValues} from '../../../../utils/objectFunctions';
import ViewSelector from '../controls/ViewSelector';
import {ViewerButton} from '../FadingComponents';
import {BUTTON_MARGIN, BUTTON_WIDTH} from '../2D/utils';
import Base3DViewport, {BoundingBox, initialParams, ViewportParams} from './Base3DViewport';
import {usePointCloudEffects} from './pointCloudHooks/usePointCloudEffects';
import {usePartPointClouds} from './pointCloudHooks/usePartPointClouds';
import {View3DViewportSidebar} from './Sidebar/View3DViewportSidebar';
import {showOverlayMessage, ViewportMessage} from './ViewportMessage';
import {MinMax} from '../../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {ViewportProps} from '../viewportProps';
import {LayerImages} from '../../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {useLayerImagePlane} from './pointCloudHooks/useLayerImagePlane';
import {initialPointCloudParams, PointCloudViewportParams} from './types/params';
import {toggleAnalysisTypeVisibility} from './viewportFunctions/control';
import {AnalysisTypeMap, CTFiltersMap} from './types/pointCloudTypes';
import {useCalibrationStoreActions, usePartModelAttachmentStoreActions} from '../../../../store/actions';
import PartRotator from './PartRotator';
import {partModelAttachmentByUuidPATCH} from '../../../../api/ajax/partModelAttachments';
import {newDebouncer} from '../../../../api/ajax';
import {Scene} from 'three';

const debounceUpdate = newDebouncer();

export interface View3DViewportProps extends ViewportProps {
  initialPartUuid?: string;
  initialAnalysisType?: string;
  fetchLayerData?: (layerNum: number) => void;
  loadedLayers: LayerImages;
}

export interface View3DViewportParams extends ViewportParams, PointCloudViewportParams {
  availableParts: IPartGETResponse[];
  selectedParts: IPartGETResponse[];
  availablePartModels?: Array<IPartModelAttachmentGETResponse>;
  selectedPartModels?: Array<IPartModelAttachmentGETResponse>;
  partModelRotationMode?: boolean;
  hoveredPartUuid?: string;

  availableAnalysisTypes: AnalysisTypeMap<boolean>;
  selectedAnalysisTypes: AnalysisTypeMap<boolean>;

  pointSizeCutoff?: {min?: number; max?: number};
  analysisAmountCutoff?: {min?: number; max?: number};
  analysisTypeSizes?: AnalysisTypeMap<MinMax<number>>;
  isSimilarity?: boolean;
  rotation?: {[uuid: string]: number};
  sourceGeometryEnabled?: boolean;
  targetGeometryEnabled?: boolean;
  comparisonScaling?: boolean;
  centerAllParts?: boolean;
  // CT Report Params
  isCtResults?: boolean;
  throughPlane?: boolean;
  overrideAnalysisTypeColors?: boolean;
  analysisTypeColorOverrides?: Partial<AnalysisTypeMap<string>>;
  ctAlignmentHelperEnabled?: boolean;
  ctFilters?: CTFiltersMap;
  activeDefectId?: number | null;
}

export const initialView3DViewportParams: View3DViewportParams = {
  ...initialParams,
  ...initialPointCloudParams,

  availableParts: [],
  selectedParts: [],
  availablePartModels: [],
  selectedPartModels: [],
  partModelRotationMode: false,
  showLayerImage: false,

  availableAnalysisTypes: objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>,
  selectedAnalysisTypes: {
    ...(objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>),
    [AnalysisType3D.Model]: true,
  },
};

export default function View3DViewport(props: View3DViewportProps) {
  // Initialise state
  const [params, setParams] = useState<View3DViewportParams>({
    ...initialView3DViewportParams,
    selectedAnalysisTypes: {
      ...initialView3DViewportParams.selectedAnalysisTypes,
      ...(props.initialAnalysisType ? {[props.initialAnalysisType]: true} : {}),
    },
  });

  const [scene] = useState(() => new Scene());
  const rerenderRef = React.useRef(() => {});
  // Function to force a rerender of the 3D viewport
  const renderScene = rerenderRef.current;

  const {viewportState, pointClouds, pointCloudData, sceneBounds, availableAnalysisTypes} = usePartPointClouds(
    params.selectedParts.map((part) => part.uuid),
    params.selectedAnalysisTypes,
    params,
    renderScene
  );

  const partStore = useSelector((state: RootState) => state.partStore);
  const partModels = useSelector((state: RootState) => state.partModelAttachmentStore.byId);
  const partModelStoreActions = usePartModelAttachmentStoreActions();

  const minLayerNum = Math.min(...params.selectedParts.map((part) => part.layerStart || 1));
  const maxLayerNum = Math.max(...params.selectedParts.map((part) => part.layerEnd || 0));
  const numLayers = maxLayerNum - minLayerNum + 1;

  // Viewport settings useEffects

  useEffect(() => {
    partModelStoreActions.ensureConsistent({buildUuid: props.build.uuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.uuid]);

  useEffect(() => {
    // Update params if available analysis types changes
    setParams((params) => ({
      ...params,
      availableAnalysisTypes,
      // Select model analysis type if none are selected
      selectedAnalysisTypes: Object.values(params.selectedAnalysisTypes).some((enabled) => enabled)
        ? params.selectedAnalysisTypes
        : initialView3DViewportParams.selectedAnalysisTypes,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableAnalysisTypes]);

  useEffect(() => {
    // Keep available partModels up-to-date
    const availablePartUuids = params.availableParts.map((part) => part.uuid);
    if (!availablePartUuids.length) return;
    const availablePartModels = Object.values(partModels).filter((partModel) =>
      availablePartUuids.includes(partModel.partUuid)
    );
    const justUploadedModel =
      params.partModelRotationMode &&
      params.selectedPartModels!.length === 0 &&
      params.selectedParts.length === 1 &&
      availablePartModels.find((partModel) => partModel.partUuid === params.selectedParts[0].uuid);

    setParams((params) => ({
      ...params,
      availablePartModels: availablePartModels,
      ...(justUploadedModel ? {selectedPartModels: [justUploadedModel]} : {}),
      rotation:
        !params.rotation && availablePartModels.length > 0
          ? availablePartModels.reduce((rotation, partModel) => {
              rotation[partModel.uuid] = partModel.rotation;
              return rotation;
            }, {} as {[uuid: string]: number})
          : params.rotation,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partModels, params.availableParts.length]);

  useEffect(() => {
    // Keep available parts up-to-date
    setParams((params) => ({
      ...params,
      availableParts: Object.values(partStore.byId).filter((part) => part.buildUuid === props.build.uuid),
      selectedParts:
        params.selectedParts.length === 0 && props.initialPartUuid
          ? [partStore.byId[props.initialPartUuid]]
          : params.selectedParts,
    }));
  }, [partStore.byId, props.build.uuid, props.initialPartUuid]);

  useEffect(() => {
    if (viewportState === 'viewing') {
      toggleAnalysisTypeVisibility(params, setParams, pointClouds, renderScene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedAnalysisTypes, pointClouds, renderScene, viewportState]);

  usePointCloudEffects(pointClouds, params, scene, renderScene, viewportState);

  const layerImageLoadingState = useLayerImagePlane(
    params.layerImagePosition,
    params.showLayerImage!,
    params.layerImageOpacity,
    pointClouds,
    renderScene,
    numLayers,
    minLayerNum,
    props.build.calibrationUuid,
    props.loadedLayers,
    props.fetchLayerData,
    sceneBounds
  );

  const calibrationStoreActions = useCalibrationStoreActions();

  useEffect(() => {
    calibrationStoreActions.ensureConsistent({uuid: props.build.calibrationUuid});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.build.calibrationUuid]);

  const plateSize = useSelector((state: RootState) =>
    props.build.calibrationUuid ? state.calibrationStore.byId[props.build.calibrationUuid].plateSize : undefined
  );

  const centreCameraFunc = useRef((_bounds: BoundingBox) => {});

  const centreCameraOnPart = (partUuid: string) => {
    const partBounds = pointCloudData.getPartBounds([partUuid]);
    if (partBounds) {
      centreCameraFunc.current(partBounds);
    }
  };

  const rotatePartModel = async (rotation: number) => {
    const selectedPartModel = params.selectedPartModels![0];

    setParams((params) => ({
      ...params,
      selectedPartModels: [{...selectedPartModel, rotation: rotation}],
      availablePartModels: params.availablePartModels!.map((partModel) =>
        partModel.uuid === selectedPartModel.uuid ? {...partModel, rotation: rotation} : partModel
      ),
      rotation: {...params.rotation, [selectedPartModel.uuid]: rotation},
    }));
    debounceUpdate(async () => await partModelAttachmentByUuidPATCH(selectedPartModel.uuid, rotation), 1000);
  };

  return (
    <Base3DViewport
      scene={scene}
      sceneGroup={pointClouds}
      sceneBounds={sceneBounds}
      params={{...params, plateSize}}
      sidebar={
        <View3DViewportSidebar
          params={params}
          sceneBounds={sceneBounds}
          centreCameraOnPart={centreCameraOnPart}
          setParams={setParams}
          resetParams={() =>
            setParams({
              ...initialView3DViewportParams,
              availableParts: params.availableParts,
              selectedParts: params.selectedParts,
              availableAnalysisTypes: params.availableAnalysisTypes,
              availablePartModels: params.availablePartModels,
              selectedPartModels: params.selectedPartModels,
            })
          }
          viewportState={viewportState}
        />
      }
      sidebarInitOpen={true}
      showOverlayMessage={showOverlayMessage(viewportState)}
      overlayMessage={
        <ViewportMessage
          viewportState={viewportState}
          noSelectionTitle="No part selected"
          noSelectionMessage="Please select a part in the sidebar to the left."
        />
      }
      showHelpers={['viewing', 'loading'].includes(viewportState)}
      rerenderRef={rerenderRef}
      centreCameraOnBoundsRef={centreCameraFunc}
      leftButtons={
        <ViewSelector
          mouseOn={true}
          on3DToggle={props.onViewportTypeToggle}
          viewport3dAvailable={true}
          is3DView={true}
        />
      }
      rightButtons={
        <>
          {props.multiViewEnabled && props.canAdd && (
            <ViewerButton
              onClick={props.onAddView}
              style={{
                right: props.alone ? BUTTON_MARGIN : BUTTON_MARGIN * 2 + BUTTON_WIDTH,
                top: BUTTON_MARGIN,
                position: 'absolute',
              }}
            >
              <Add /> Add View
            </ViewerButton>
          )}

          <div
            style={{
              right: props.alone ? BUTTON_MARGIN : BUTTON_MARGIN * 2 + BUTTON_WIDTH,
              top: BUTTON_MARGIN + 40,
              position: 'absolute',
              color: 'white',
            }}
          >
            {layerImageLoadingState !== 'viewing' && (
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                <Typography>
                  {layerImageLoadingState === 'loading' ? 'Layers Loading' : "Couldn't load Layer Images"}
                </Typography>
                {layerImageLoadingState === 'loading' && <CircularProgress size="20px" />}
              </div>
            )}
          </div>
          {props.multiViewEnabled && !props.alone && (
            <Tooltip title="Close" placement="left">
              <ViewerButton
                onClick={props.onClose}
                style={{
                  right: BUTTON_MARGIN,
                  top: BUTTON_MARGIN,
                  position: 'absolute',
                }}
              >
                <Close />
              </ViewerButton>
            </Tooltip>
          )}
          {!!params.partModelRotationMode && viewportState === 'viewing' && (
            <PartRotator
              rotation={params.selectedPartModels![0]?.rotation ?? 0}
              setRotation={(rotationValue) => {
                rotatePartModel(rotationValue);
              }}
              mode="stl"
            />
          )}
        </>
      }
      {...props}
    />
  );
}

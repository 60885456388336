export interface IBuildEvent {
  uuid: string;
  userUuid: string;
  buildUuid: string;
  type: BuildEventType;
  eventTime: Date;
  successTime?: Date;
}

export interface IBuildEventTableRecord {
  uuid: string;
  userName: string;
  eventTime: Date;
  successTime: Date;
  type: BuildEventType;
}

export enum BuildEventType {
  PROVISION = 'provision',
  CALIBRATE = 'calibrate',
  AUTOFOCUS = 'autofocus',
  MANUAL_FOCUS = 'manual_focus',
  MONITOR = 'monitor',
  MONITOR_PAUSE = 'monitor_pause',
  MONITOR_RESUME = 'monitor_resume',
  MONITOR_COMPLETE = 'monitor_complete',
}

import {
  BackSide,
  BoxGeometry,
  BufferGeometry,
  Color,
  FrontSide,
  InstancedMesh,
  Matrix4,
  Mesh,
  MeshPhongMaterial,
  MeshPhysicalMaterial,
  Object3D,
  Points,
  Quaternion,
  Vector3,
} from 'three';
import {ComparisonLoadResult, ExplicitPoint, PointCloudLoadResult, SimilarityPoint} from '../types/pointCloudTypes';
import {clone} from 'lodash';
import {PointCloudViewportParams} from '../types/params';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {PointCloud2} from '../types/pointCloudV2';
import {View3DViewportParams} from '../View3DViewport';
import {PointCloud3} from '../types/pointCloudV3';
import {loadComparisons, loadPointCloudBounds, loadPoints} from './pointLoaders';

/**
 * Loads instanced mesh matrices based on the provided points and settings.
 * @param mesh - The instanced mesh to load the matrices into.
 * @param points - An array of explicit points.
 * @param scale - The scale factor to apply to the points.
 * @param shouldDisplayFn - Optional. A function that determines whether a point should be displayed.
 * @param getBlockColour - Optional. A function that returns the color for a point.
 */
export const loadInstancedMeshMatrices = (
  mesh: InstancedMesh,
  points: ExplicitPoint[],
  scale: number,
  analysisType: AnalysisType3D,
  shouldDisplayFn?: (point: ExplicitPoint, analysisType: AnalysisType3D) => boolean,
  getBlockColour?: (point: ExplicitPoint & Partial<SimilarityPoint>) => Color
) => {
  // We don't ever need rotation, fix it to the unit quaternion
  const rotation = new Quaternion();

  const getTranslationMatrix = (point: ExplicitPoint, outputMatrix: Matrix4) => {
    const scaleVector = new Vector3();
    scaleVector.x = scaleVector.y = scaleVector.z = scale * point.scale;
    outputMatrix.compose(point.position, rotation, scaleVector);
  };

  const tempMatrix = new Matrix4();
  points.forEach((point, index) => {
    if (!shouldDisplayFn || shouldDisplayFn(point, analysisType)) {
      getTranslationMatrix(point, tempMatrix);
      mesh.setMatrixAt(index, tempMatrix);
      if (getBlockColour) {
        mesh.setColorAt(index, getBlockColour(point));
      } else {
        mesh.setColorAt(index, point.colour);
      }
    } else {
      const hiddenPoint = clone(point);
      hiddenPoint.scale = 0;
      getTranslationMatrix(hiddenPoint, tempMatrix);
      mesh.setMatrixAt(index, tempMatrix);
    }
  });
  mesh.instanceMatrix.needsUpdate = true;
  // @ts-ignore
  mesh.instanceColor.needsUpdate = true;
};

/**
 * Loads a mesh object with the given parameters.
 *
 * @param points - An array of explicit points.
 * @param params - The viewport parameters for the point cloud.
 * @param analysisType - The type of analysis being performed in 3D.
 * @param geometry - The buffer geometry for the mesh.
 * @returns The loaded mesh object.
 */
export const loadMeshObject = (
  points: ExplicitPoint[],
  params: PointCloudViewportParams,
  analysisType: AnalysisType3D,
  geometry: BufferGeometry
) => {
  const isModel = analysisType === AnalysisType3D.Model;
  const transparent = params.isTransparent || isModel;
  const overridePointColour = isModel && params.overridePointColour;
  const material = new MeshPhongMaterial({
    transparent: transparent,
    clippingPlanes: [params.clippingPlane],
    opacity: transparent ? params.modelOpacity : 1,
    color: overridePointColour ? new Color(params.overrideColour) : new Color(0xffffff),
    depthWrite: geometry instanceof BoxGeometry && params.modelOpacity < 1 && transparent ? false : true,
  });

  const mesh = new InstancedMesh(geometry, material, points.length);
  loadInstancedMeshMatrices(mesh, points, params.pointSize, analysisType);

  // We need to render the model after the defects, so that the defects are
  // visible through the model when transparent.
  mesh.renderOrder = analysisType === AnalysisType3D.Model ? 2 : 1;

  return mesh;
};

//
/**
 * Loads a point cloud as a mesh.
 *
 * @param pointCloud - The point cloud data.
 * @param analysisType - The type of analysis.
 * @param params - The viewport parameters.
 * @param geometry - The buffer geometry.
 * @returns The result of loading the point cloud as a mesh.
 */
export const loadPointCloudAsMesh = (
  pointCloud: PointCloud2 | PointCloud3,
  analysisType: AnalysisType3D,
  params: View3DViewportParams,
  geometry: BufferGeometry
): PointCloudLoadResult => {
  const points = loadPoints(pointCloud, params.centerAllParts);

  const object = loadMeshObject(points, params, analysisType, geometry);
  object.name = `${analysisType}Partition${pointCloud.partitionNum}`;
  object.userData.pointData = points;

  const bounds = loadPointCloudBounds(pointCloud);

  return {success: true, object, bounds};
};

/**
 * Loads similarity comparisons as a mesh.
 * @param pointCloud - The point cloud data.
 * @param params - The viewport parameters.
 * @param geometry - The buffer geometry.
 * @returns The result of the comparison load operation.
 */
export const loadSimilarityComparisonsAsMesh = (
  pointCloud: PointCloud2 | PointCloud3,
  params: View3DViewportParams,
  geometry: BufferGeometry
): ComparisonLoadResult => {
  const comparisonPoints = loadComparisons(pointCloud, params.comparisonScaling!);
  const result: {[key in AnalysisType3D]?: Object3D} = {};

  Object.entries(comparisonPoints).forEach(([type, points]) => {
    const analysisType = type as AnalysisType3D;
    const object = loadMeshObject(points!, params, analysisType, geometry);

    object.name = `${analysisType}Partition${pointCloud.partitionNum}`;
    object.userData.pointData = points;
    result[analysisType] = object;
  });

  return {success: true, comparisonPoints: result};
};

/**
 * Converts a point cloud to a mesh object.
 *
 * @param pointCloud - The point cloud to convert.
 * @param analysisType - The type of analysis being performed on the point cloud.
 * @param params - The parameters for the point cloud viewport.
 * @param geometry - The buffer geometry for the mesh.
 * @returns The converted mesh object.
 */
export const convertPointsToMesh = (
  pointCloud: Points,
  analysisType: AnalysisType3D,
  params: PointCloudViewportParams,
  geometry: BufferGeometry
) => {
  const points = pointCloud.userData.pointData;

  const mesh = loadMeshObject(points, params, analysisType, geometry);
  mesh.visible = pointCloud.visible;
  mesh.name = pointCloud.name;
  mesh.userData = pointCloud.userData;
  // @ts-ignore
  mesh.instanceColor = pointCloud.geometry.attributes.color;
  return mesh;
};

export const loadPartModelAsMesh = (geometry: BufferGeometry, params: View3DViewportParams) => {
  const frontMaterial = new MeshPhysicalMaterial({
    color: params.overridePointColour ? new Color(params.overrideColour) : new Color(0xffffff),
    clippingPlanes: [params.clippingPlane],
    metalness: 0.25,
    roughness: 1,
    opacity: params.modelOpacity,
    transparent: true,
    clearcoat: 1.0,
    clearcoatRoughness: 1,
    side: FrontSide,
  });
  const backMaterial = new MeshPhysicalMaterial({
    color: params.overridePointColour ? new Color(params.overrideColour) : new Color(0xffffff),
    clippingPlanes: [params.clippingPlane],
    metalness: 0.25,
    roughness: 1,
    opacity: params.modelOpacity,
    transparent: true,
    clearcoat: 1.0,
    clearcoatRoughness: 1,
    side: BackSide,
  });

  const frontMesh = new Mesh(geometry, frontMaterial);
  const backMesh = new Mesh(geometry, backMaterial);

  frontMesh.renderOrder = 3;
  backMesh.renderOrder = 3;

  return {frontMesh, backMesh};
};

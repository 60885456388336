import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, IconButton, Tooltip, Typography} from '@material-ui/core';
import {IBuildNote} from '@common/api/models/builds/IBuildNote';
import {Delete} from '@material-ui/icons';
import {renderDateTimeString} from '../../../../../src/utils/string';
import EllipsisTextWithTooltip from '../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {RootState} from '../../../../store/reducers';
import {GenericDialog} from '../../../../components/molecules/DialogButton';
import {deleteBuildNoteDELETE} from '../../../../api/ajax/buildNotes';

const BuildNoteDeleteButton = ({rowData}: {rowData: BuildNoteRow}) => {
  const auth = useSelector((s: RootState) => s.auth);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  return (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip
        title={rowData.userUuid === auth.user?.uuid ? 'Delete Note' : `Only ${rowData.userName} can delete this note`}
      >
        <IconButton
          aria-label="delete"
          onClick={(event) => {
            event.stopPropagation();
            setDeleteDialogOpen(true);
          }}
        >
          <Delete />
        </IconButton>
      </Tooltip>
      <GenericDialog
        title="Delete Note"
        content={`Are you sure you want to delete the note "${
          // Limit note preview to 100 chars
          rowData.note.length > 100 ? `${rowData.note.substring(0, 100)}...` : rowData.note
        }"?`}
        confirmText="Delete"
        isOpen={deleteDialogOpen}
        closeDialog={() => setDeleteDialogOpen(false)}
        onSuccess={async () => {
          await deleteBuildNoteDELETE(rowData?.buildUuid, rowData?.createdAt);
          setDeleteDialogOpen(false);
        }}
        danger={true}
      />
    </Box>
  );
};

export interface BuildNoteRow extends IBuildNote {
  userName: string;
}

export const buildNoteTableSchema = [
  {
    title: 'Name',
    field: 'name',
    width: '128px',
    sorting: false,
    render: (row: BuildNoteRow) => <EllipsisTextWithTooltip>{row.userName}</EllipsisTextWithTooltip>,
  },
  {
    title: 'Time',
    field: 'createdAt',
    type: 'datetime',
    width: '168px',
    sorting: false,
    render: (row: BuildNoteRow) => <Typography>{renderDateTimeString('short', row.createdAt, true)}</Typography>,
  },
  {
    title: 'Message',
    field: 'note',
    headerStyle: {minWidth: 250},
    sorting: false,
    render: (row: BuildNoteRow) => row.note.split('\n').map((line) => <Typography>{line}</Typography>),
  },
  {
    title: 'Actions',
    width: '112px',
    sorting: false,
    render: (rowData: BuildNoteRow) => <BuildNoteDeleteButton rowData={rowData} />,
  },
];

export default buildNoteTableSchema;
